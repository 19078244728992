import { makeStyles } from '@mui/styles';
import { BeeplomaTheme } from '../../theme/BeeplomaTheme';

export const useStyles = makeStyles({
  logosbox: {
    '@media (min-width: 769px)': {
      position: 'absolute',
      display: 'flex',
      top: 'calc(70% - 100px)',
      left: 'calc(calc(calc(100% - 1280px) / 4) - 80px)',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1em',
      '& #tech-img': {
        width: '160px',
        aspectRatio: '0.93',
      },
      '& #logo-img': {
        width: '130px',
        aspectRatio: '1',
        '& .cls-2': {
          fill: '#fff',
        },
      },
    },
    '@media (max-width: 768px)': {
      position: 'absolute',
      top: 'calc(98% - 60px)',
      display: 'flex',
      alignItems: 'center',
      gap: '0.3em',
      left: '10px',
      '& #tech-img': {
        width: '60px',
        aspectRatio: '0.93',
        '& .cls-1': {
          fill: '#000',
        },
      },
      '& #logo-img': {
        width: '50px',
        aspectRatio: '1',
        '& .cls-2': {
          fill: '#000',
        },
      },
    },
  },
  duocolor: {
    position: 'absolute',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '50%',
      height: '100%',
      top: '0',
      zIndex: '0',
    },
    '&:before': {
      left: '0',
      backgroundColor: 'black',
    },
    '&:after': {
      right: '0',
      backgroundColor: 'white',
    },
  },
  corner: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      right: '0',
      width: '0px',
      height: '0px',
      borderTop: '75px solid transparent',
      borderRight: '75px solid transparent',
      borderBottom: '75px solid transparent',
      borderLeft: '75px solid transparent',
    },
    '&.left': {
      '&:after': {
        left: '-150px',
      },
    },
    '&.white': {
      '&:after': {
        borderTopColor: 'white',
        borderRightColor: 'white',
      },
    },
    '&.grey': {
      '&:after': {
        borderTopColor: BeeplomaTheme.palette.background.grey,
        borderRightColor: BeeplomaTheme.palette.background.grey,
      },
    },
    '&.yellow': {
      '&:after': {
        borderTopColor: BeeplomaTheme.palette.secondary.main,
        borderRightColor: BeeplomaTheme.palette.secondary.main,
      },
    },
  },
});
