import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

function FormAutocomplete({
  textFieldProps,
  autocompleteProps,
  name,
  options,
  loading,
  showCheckbox,
  rules,
  required,
  multiple,
  label,
  store = 'options', // options, name, id
  freeSolo,
  filterOptions,
  limit,
  disableOptionBasedOnValue = () => false,
  ...props
}) {
  const { control } = useFormContext();
  const validationRules = {
    ...rules,
    ...(required && {
      required: rules?.required || 'This field is required',
    }),
  };
  const {
    field: { onChange, onBlur, value, ...fieldRest },
    fieldState: { error },
  } = useController({ name, control, rules: validationRules });
  let currentValue = multiple ? value || [] : value || null;
  if (store !== 'options') {
    currentValue = multiple
      ? value?.map((storedParam) => options.find((field) => field?.[store] === storedParam)) ?? []
      : options?.find((field) => field?.[store] === value) || null;
  }

  const handleChange = useCallback(
    (event, value, reason, details) => {
      let changedVal;
      if (store !== 'options') {
        if (multiple) {
          changedVal = value.map((v) => v?.[store]) ?? [];
        } else {
          changedVal = value?.[store] ?? '';
        }
      } else {
        changedVal = value ?? (multiple ? [] : '');
      }
      const newElementIndex = value?.length ? value.length - 1 : -1;
      if (newElementIndex >= 0 && value[newElementIndex].inputValue) {
        options.push({
          id: value[newElementIndex].id,
          name: value[newElementIndex].name,
        });
      }

      onChange(changedVal);
      if (autocompleteProps?.onChange) {
        autocompleteProps.onChange(event, value, reason, details);
      }
    },
    [autocompleteProps, onChange]
  );

  const handleOptionEqualToValue = autocompleteProps?.isOptionEqualToValue
    ? autocompleteProps.isOptionEqualToValue
    : (option, value) => (value ? option.id === (value?.id || value) : false);

  const getOptionLabel = autocompleteProps?.getOptionLabel
    ? autocompleteProps.getOptionLabel
    : (option) => `${option?.label || option}`;

  const renderOption = useCallback(
    autocompleteProps?.renderOption ??
      (showCheckbox
        ? (props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ marginRight: 1 }} checked={selected} />
              {autocompleteProps?.getOptionLabel?.(option) || option.label || option}
            </li>
          )
        : undefined),
    [autocompleteProps?.renderOption, autocompleteProps?.getOptionLabel]
  );

  const getOptionDisabled = (option) =>
    typeof autocompleteProps?.getOptionDisabled === 'function'
      ? autocompleteProps.getOptionDisabled(option)
      : disableOptionBasedOnValue(option, value);

  const handleBlur = (event) => {
    onBlur();
    if (typeof autocompleteProps?.onBlur === 'function') {
      autocompleteProps.onBlur(event);
    }
  };
  const renderInput = (params) => (
    <TextField
      name={name}
      required={rules?.required ? true : required}
      label={label}
      {...textFieldProps}
      {...params}
      error={!!error}
      inputProps={{
        ...params?.inputProps,
        endadornment: (
          <>
            {loading ? <CircularProgress color='inherit' size={20} /> : null}
            {params?.InputProps?.endadornment}
          </>
        ),
        ...textFieldProps?.inputProps,
      }}
      helperText={error ? error.message : textFieldProps?.helperText}
    />
  );

  return (
    <Autocomplete
      {...props}
      {...autocompleteProps}
      loading={loading}
      value={currentValue}
      options={options}
      multiple={multiple}
      freeSolo={freeSolo}
      disableCloseOnSelect={
        typeof autocompleteProps?.disableCloseOnSelect === 'boolean'
          ? autocompleteProps.disableCloseOnSelect
          : !!multiple
      }
      isOptionEqualToValue={handleOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={limit ? () => value.length >= limit : getOptionDisabled}
      onChange={handleChange}
      filterOptions={filterOptions}
      renderOption={renderOption}
      onBlur={handleBlur}
      renderInput={renderInput}
      {...fieldRest}
    />
  );
}

export default FormAutocomplete;
