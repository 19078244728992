import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function FormDateTimePicker({
  isDate,
  parseError = (error) => error?.message,
  name,
  required,
  parseDate,
  rules = {},
  inputProps,
  textReadOnly,
  ...rest
}) {
  const { control } = useFormContext();

  if (required && !rules.required) {
    rules.required = 'This field is required';
  }

  const {
    field: { onChange, value, onBlur },
    fieldState: { error, invalid },
  } = useController({ name, control, rules });

  const handleClose = (...args) => {
    onBlur();
    if (rest.onClose) rest.onClose(...args);
  };

  const handleChange = useCallback(
    (value, keyboardInputValue) => {
      let newValue;
      value = dayjs(value).second(0).millisecond(0);
      if (keyboardInputValue) {
        if (typeof parseDate === 'function') {
          newValue = parseDate(value, keyboardInputValue);
        } else {
          newValue = value;
        }
      } else if (typeof parseDate === 'function') {
        newValue = parseDate(value);
      } else {
        newValue = value;
      }
      onChange(newValue, keyboardInputValue);
      if (typeof rest.onChange === 'function') {
        rest.onChange(newValue, keyboardInputValue);
      }
    },
    [parseDate, onChange, rest.onChange]
  );

  const onKeyDownBlock = (e) => {
    e.preventDefault();
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      onBlur={(...args) => {
        onBlur();
        if (params.onBlur) params.onBlur(...args);
      }}
      inputProps={{
        ...params?.inputProps,
        ...(!value && {
          value: '',
        }),
        ...(textReadOnly && {
          readOnly: true,
        }),
      }}
      {...inputProps}
      required={!!required}
      error={invalid}
      onKeyDown={onKeyDownBlock}
      helperText={error ? parseError(error) : inputProps?.helperText || rest.helperText}
    />
  );

  return (
    <DateTimePicker
      {...rest}
      value={value || ''}
      onClose={handleClose}
      onChange={handleChange}
      renderInput={renderInput}
    />
  );
}

export default FormDateTimePicker;
