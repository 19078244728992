import {
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Select,
  Chip,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { listPaperStyle } from 'Components/Courses/HandleCourse/style';
import { useController, useFormContext } from 'react-hook-form';
import { findParticipantNameById } from 'utils/findParticipants';
import { findPlaceIdByName, findPlaceNameById } from 'utils/findPlace';
import { inputStyle } from './styles';

function FormSelectParticipants({ coursePlaces, places, participants, name, disabled }) {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const addPerson = (place, person) => (e) => {
    if (!e.target.checked) {
      field.onChange(field.value.filter((participant) => participant.participant_id !== person));
      return;
    }
    const participantsValue = [...field.value];
    const index = participantsValue.findIndex((item) => item.participant_id === person);
    if (index !== -1) {
      participantsValue.splice(index, 1);
    }
    participantsValue.push({ participant_id: person, place_id: place });
    field.onChange(participantsValue);
  };

  const renderValue = (items) =>
    items.map((item) => (
      <Chip
        key={item.participant_id}
        label={findParticipantNameById(participants, item.participant_id)}
        sx={{ marginLeft: '5px' }}
      />
    ));

  return (
    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
      <InputLabel id='participants' sx={{ top: '-8px' }}>
        Participants
      </InputLabel>
      <Select
        name={field.name}
        labelId='participants'
        label='Participants'
        value={field.value}
        renderValue={renderValue}
        multiple
        sx={inputStyle}
        size='small'
        variant='outlined'
        MenuProps={listPaperStyle}
        disabled={disabled}
      >
        {participants.map((participant) => (
          <MenuItem key={participant.id}>
            <Box style={{ display: 'flex', justifyContent: 'space-evenly', width: '95%' }}>
              <ListItemText primary={`${participant.first_name} ${participant.last_name}`} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {coursePlaces?.map((place) =>
                  place !== findPlaceIdByName(places, 'External') ? (
                    <div key={`${participant.id}: ${place}`}>
                      <FormControlLabel
                        label={findPlaceNameById(places, place)}
                        control={
                          <Checkbox
                            onClick={addPerson(place, participant.id)}
                            checked={
                              field.value.find(
                                (person) => person.participant_id === participant.id && person.place_id === place
                              ) !== undefined
                            }
                          />
                        }
                      />
                    </div>
                  ) : null
                )}
              </div>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FormSelectParticipants;
