import { Typography } from '@mui/material';
import { dateTimeFormat } from 'constants/global';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import withSkeleton from 'Components/withSkeleton';
import { Box } from '@mui/system';

const SkeletonTypography = withSkeleton(Typography);

export const formatDate = (start, end) => {
  const [endDate, endTime] = dayjs(end).format(dateTimeFormat).split(' ');
  const [startDate, startTime] = dayjs(start).format(dateTimeFormat).split(' ');
  return `${startDate} ${startTime} -${endDate === startDate ? '' : ` ${endDate}`} ${endTime}`;
};

export const CourseDate = React.memo(({ start, end }) => {
  const loading = useMemo(() => !(start && end), [start, end]);
  return (
    <Box paddingRight='2rem'>
      <SkeletonTypography
        loading={loading}
        color='primary'
        variant='p1bold'
        skeletonStyle={{ width: 120, whiteSpace: 'pre' }}
      >
        {`${formatDate(start, end)}`}
      </SkeletonTypography>
    </Box>
  );
});
