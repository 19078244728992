import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { InstructorsList } from 'Components/InstructorsList/InstructorsList';
import { containerBoxStyle } from './style';

export function ListOfInstructors() {
  return (
    <Box sx={containerBoxStyle} minHeight='calc(100vh - 90px)'>
      <Box>
        <Box display='flex' gap='1rem'>
          <Typography variant='h3'>💁</Typography>
          <Box>
            <Typography variant='h3bold' sx={{ display: 'block', marginBottom: '30px' }}>
              Instructors
            </Typography>
            <Typography variant='p1bold'>Let's get to know Instructors!</Typography>
          </Box>
        </Box>
        <InstructorsList />
      </Box>
    </Box>
  );
}
