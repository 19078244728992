import { useQuery } from 'react-query';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_COURSE_DETAILS } from '../queries';

export const useGetCourseDetails = (courseId) => {
  const {
    data: courseDetails,
    error,
    isLoading,
    refetch: refetchDetails,
  } = useQuery([GET_COURSE_DETAILS, courseId], () => CoursesController.getCourseDetails(courseId), {
    enabled: false,
  });

  return {
    courseDetails,
    error,
    isLoading,
    refetchDetails,
  };
};
