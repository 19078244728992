export const courseStates = {
  registered: 'registered',
  owned: 'owned',
  unregistered: 'unregistered',
  draft: 'draft',
  admin: 'admin',
  isNotFinished: 'isNotFinished',
};

export const courseStatuses = {
  draft: 'draft',
  published: 'published',
};
