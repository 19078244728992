import Badge from '@mui/material/Badge';
import { SkeletonAvatar } from 'Components/withSkeleton';
import { useMemo } from 'react';
import { BeeplomaTheme } from 'theme/BeeplomaTheme';

function CardAvatar({ availablePlaces, placeholder, badgeDisabled }) {
  const color = useMemo(() => {
    if (availablePlaces > 0) return 'secondary';
    return 'lightGrey';
  }, [availablePlaces]);
  return (
    <Badge
      badgeContent={Number.isNaN(Math.max(availablePlaces, 0)) ? '' : Math.max(availablePlaces, 0)}
      color={color}
      overlap='circular'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      invisible={badgeDisabled}
      showZero
    >
      <SkeletonAvatar
        sx={{ width: '50px', height: '50px', bgcolor: BeeplomaTheme.palette.blue.main }}
        skeletonStyle={{ bgcolor: BeeplomaTheme.palette.blue.main, borderColor: BeeplomaTheme.palette.blue.light }}
      >
        {placeholder}
      </SkeletonAvatar>
    </Badge>
  );
}

export default CardAvatar;
