import { useGetAllTags } from 'hooks/hashtags/useGetAllTags';
import { useGetAll } from '../../hooks/users/useGetAll';
import { useGetAll as useGetAllPlaces } from '../../hooks/places/useGetAll';
import UserDataContext from './context';

export function UserDataProvider({ children }) {
  const { users } = useGetAll();
  const { places } = useGetAllPlaces();
  const { tags } = useGetAllTags();

  const value = {
    state: { users: users?.items?.map((x) => x), places, tags },
  };

  return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
}
