export const userDetailsLabelStyle = {
  fontSize: '1rem',
  fontWeight: '700',
  color: 'lightGrey.dark',
  textAlign: 'end',
  width: '100px',
  placeSelf: 'center end',
};

export const profileContainer = {
  marginTop: '2rem',
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  columnGap: '1.3rem',
  rowGap: '2rem',
};

export const iconButton = {
  bgcolor: 'lightGrey.light',
  '&:hover': {
    bgcolor: 'lightGrey.main',
  },
};
