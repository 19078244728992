import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useStyles } from './styles';

export function HowToUseCertificate() {
  const styles = useStyles();
  return (
    <Box p={2} minHeight='calc(100vh - 90px)'>
      <Box mb={2} mt={1}>
        <Typography variant='h5'>Beeploma instructions</Typography>
      </Box>
      <Box mb={2} mt={1}>
        <Typography variant='body1'>
          After completing a course you should receive an email with congratulations. There would be an attached pdf
          with generated certificate just for you. In the right corner of your diploma there will be a qr code which you
          can scan in order to get digital information about certificate validation. Moreover in this email message
          there should be a link which can help you sharing your diploma on Linkedin account.
        </Typography>
      </Box>
      <Box mb={3} mt={2}>
        <Divider />
      </Box>
      <Box height='100%' zIndex='1' id='content'>
        <img src='scan-instruction.png' alt='tech academy logo' className={styles.certificate} />
      </Box>
    </Box>
  );
}
