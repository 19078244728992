import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { courseStates, courseStatuses } from 'constants/course';
import withSkeleton from 'Components/withSkeleton';
import { GetInitials } from 'utils/GetInitials.js';
import { useUserData } from 'providers/user';
import { Chip, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { CourseDate } from './CourseDate';
import {
  DetailsButton,
  CourseCard,
  ActionButton,
  ButtonsSkeleton,
  tagListStyle,
  draftTagListStyle,
  truncateStyle,
} from './styles';
import { CourseDetailsDrawer } from './CourseDetailsDrawer';
import CardAvatar from './CardAvatar';
import { HandleFinishCourse } from '../HandleFinishCourse/HandleFinishCourse';

const SkeletonTypography = withSkeleton(Typography);

export function CourseListItem({ courseDetails, registrationAction, state }) {
  const route = useLocation();
  const drawerRef = useRef(null);
  const [instructorName, setIntructorName] = useState(undefined);
  const finishDialogRef = useRef(null);
  const {
    state: { users },
  } = useUserData();
  const handleFinishClick = useCallback(() => {
    if (finishDialogRef.current) {
      finishDialogRef.current.open();
    }
  }, [finishDialogRef.current]);

  const listAction = useMemo(() => {
    if (state !== courseStates.owned) return registrationAction;
    return {
      takeAction: handleFinishClick,
    };
  }, [state, registrationAction, handleFinishClick]);
  const showActionButton = useMemo(
    () =>
      !!registrationAction &&
      !courseDetails?.is_finished &&
      dayjs(courseDetails?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') >
        dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    [listAction]
  );

  const badgeDisabled = useMemo(
    () =>
      !courseDetails ||
      dayjs(courseDetails?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') <
        dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS') ||
      courseDetails?.status === 'draft' ||
      courseDetails?.is_finished,
    [(state, courseDetails)]
  );

  useEffect(() => {
    const user = users?.find((user) => user?.id === courseDetails?.instructor_id);
    if (user === undefined) {
      setIntructorName(undefined);
      return;
    }
    setIntructorName(`${user.first_name} ${user.last_name}`);
  }, [courseDetails, users]);

  const handleExpandClick = useCallback(() => {
    if (drawerRef?.current) drawerRef.current.open();
  }, [drawerRef]);
  const buttonsMenu = useCallback(
    () =>
      courseDetails ? (
        <>
          {showActionButton && courseDetails.status === courseStatuses.published && (
            <ActionButton
              courseState={state}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                listAction?.takeAction();
              }}
              loading={listAction?.isLoading}
              disabled={listAction?.isDisabled}
            />
          )}
          <DetailsButton
            variant='contained'
            courseState={state}
            isAlone={!showActionButton}
            onClick={handleExpandClick}
          />
        </>
      ) : (
        <ButtonsSkeleton id='course-card-button-skeleton' />
      ),
    [registrationAction, showActionButton, courseDetails, state, handleExpandClick]
  );
  return (
    <div style={{ position: 'relative' }}>
      <CourseCard courseState={state} disabled={!courseDetails} onClick={handleExpandClick}>
        <CardHeader
          sx={{ padding: '0' }}
          avatar={
            <CardAvatar
              placeholder={GetInitials(instructorName)}
              availablePlaces={
                (courseDetails?.no_available_places || 0) + (courseDetails?.no_available_places_online || 0)
              }
              badgeDisabled={badgeDisabled}
            />
          }
          title={
            <Tooltip title={courseDetails?.name} followCursor disableHoverListener={!(courseDetails?.name.length > 30)}>
              <Box paddingRight='2rem' sx={courseDetails?.name.length > 20 ? truncateStyle : null}>
                <SkeletonTypography
                  loading={!courseDetails}
                  style={{ wordBreak: 'break-word' }}
                  component='span'
                  lineHeight='normal'
                  variant='h6bold'
                  skeletonStyle={{ width: 80 }}
                >
                  {courseDetails?.name}
                </SkeletonTypography>
              </Box>
            </Tooltip>
          }
          subheader={<CourseDate start={courseDetails?.meeting_start_time} end={courseDetails?.meeting_end_time} />}
        />
        <Box sx={{ display: 'flex', gap: '10px', alignSelf: 'center' }}>
          {route.pathname.includes('/courses-filtration') && courseDetails?.status === courseStatuses.draft ? (
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Chip key={courseDetails.id} label='#draft' sx={draftTagListStyle} />
            </Box>
          ) : null}
          {route.pathname.includes('/courses-filtration') ? (
            <Box sx={{ display: 'flex', gap: '10px', paddingRight: '60px' }}>
              {courseDetails?.tags.map((tag) => (
                <Chip key={`${courseDetails.id}${tag}`} label={`#${tag}`} sx={tagListStyle} />
              ))}
            </Box>
          ) : null}
        </Box>
      </CourseCard>
      {buttonsMenu()}
      <CourseDetailsDrawer
        ref={drawerRef}
        courseId={courseDetails?.id}
        courseState={state}
        instructorName={instructorName}
        onRegisterClick={registrationAction?.takeAction}
        onFinishClick={handleFinishClick}
        isActionLoading={registrationAction?.isLoading}
      />
      <HandleFinishCourse courseId={courseDetails?.id} ref={finishDialogRef} />
    </div>
  );
}
