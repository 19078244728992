import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';

function FormContainer({
  children,
  formProps,
  defaultValues,
  loadValues,
  onChange = () => {},
  onSuccess = () => {},
  useFormProps,
}) {
  const methods = useForm({
    ...useFormProps,
    defaulValues: defaultValues ?? useFormProps?.defaulValues,
  });
  const { handleSubmit, watch, setValue } = methods;

  const formWatch = watch();

  useEffect(() => {
    if (loadValues) {
      Object.entries(loadValues).map(([name, value]) => setValue(name, value));
    }
  }, [loadValues]);

  useEffect(() => {
    onChange(formWatch);
  }, [formWatch]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSuccess)} noValidate {...formProps}>
        {children}
      </form>
    </FormProvider>
  );
}

export default React.memo(FormContainer);
