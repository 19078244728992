import { Client } from '../../client/Client';
import { COURSES_ENDPOINT } from '../../config/Client';

export class CoursesController {
  static getCourses(status) {
    return Client.get(`${COURSES_ENDPOINT}?status=${status}`);
  }

  static getCoursesFilters(params) {
    const sParams = new URLSearchParams();
    Object.entries(params).forEach(([paramKey, paramValue]) => {
      if (!paramValue) {
        return;
      }
      if (Array.isArray(paramValue)) {
        paramValue.forEach((value) => sParams.append(paramKey, value));
        return;
      }
      sParams.append(paramKey, paramValue);
    });
    const searchP = `${COURSES_ENDPOINT}?${sParams.toString()}`;
    return Client.get(searchP);
  }

  static getMyCourses() {
    return Client.get(`${COURSES_ENDPOINT}?mine=true`);
  }

  static createCourse(course) {
    return Client.post(`${COURSES_ENDPOINT}`, course);
  }

  static getCourseDetails(courseId) {
    return Client.get(`${COURSES_ENDPOINT}/${courseId}`);
  }

  static deleteCourse(courseId) {
    return Client.delete(`${COURSES_ENDPOINT}/${courseId}`);
  }

  static cancelCourse(courseId) {
    return Client.post(`${COURSES_ENDPOINT}/${courseId}/cancel`);
  }

  static finishCourse(courseId, participant_ids) {
    return Client.post(`${COURSES_ENDPOINT}/${courseId}/finish`, { participant_ids });
  }

  static addSelf(courseId, placeId) {
    return Client.post(`${COURSES_ENDPOINT}/${courseId}/assign`, { place_id: placeId });
  }

  static deleteSelf(courseId) {
    return Client.delete(`${COURSES_ENDPOINT}/${courseId}/remove`);
  }

  static updateCourse(courseId, changes) {
    return Client.patch(`${COURSES_ENDPOINT}/${courseId}`, { patch: changes });
  }

  static deleteCourseParticipant(courseId, participantId) {
    return Client.delete(`${COURSES_ENDPOINT}/${courseId}/participants/${participantId}`);
  }

  static getDiplomaForParticipant(courseId, participantId) {
    return Client.get(`${COURSES_ENDPOINT}/${courseId}/participants/${participantId}/diploma`);
  }
}
