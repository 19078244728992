import { Typography } from '@mui/material';
import { useCallback, forwardRef } from 'react';
import FormDialog from 'Components/Form/FormDialog';
import { useGetCourseDetails, useUpdateCourse } from 'hooks/courses';
import * as jsonpatch from 'fast-json-patch/index';
import ActionButton, { actionButtonIconStyle } from 'Components/Button/ActionButton';
import SaveIcon from '@mui/icons-material/Save';
import { HandleParticipantForm } from './HandleParticipantForm';

export const HandleParticipant = forwardRef(({ courseId, formId, onSuccess = () => {}, onCancel = () => {} }, ref) => {
  const handleCreateError = (error) => {};
  const { courseDetails } = useGetCourseDetails(courseId);

  const handleCancel = useCallback(() => {
    onCancel();
    if (ref?.current) {
      ref?.current.close();
    }
  }, [ref?.current, onCancel]);

  const handleSuccess = useCallback(() => {
    onSuccess();
    handleCancel();
  }, [handleCancel, onSuccess]);

  const { updateCourse, error: createError, isLoading: isSaving } = useUpdateCourse({ onSuccess: handleSuccess });

  const handleSave = useCallback(
    (data) => {
      updateCourse({ courseId: courseDetails?.id, course: jsonpatch.compare(courseDetails, data) });
    },
    [courseDetails, updateCourse]
  );

  const title = useCallback(
    () => (
      <Typography variant='h6' style={{ wordBreak: 'break-word', marginBottom: '1rem' }}>
        Select course participants:
      </Typography>
    ),
    []
  );

  const buttons = useCallback(
    () => (
      <ActionButton
        variant='save'
        type='submit'
        form={formId}
        loading={isSaving}
        icon={<SaveIcon sx={actionButtonIconStyle} />}
        text='save'
      />
    ),
    [formId, courseId, isSaving]
  );

  const form = useCallback(
    () => <HandleParticipantForm id={formId} onSubmit={handleSave} courseDetails={courseDetails} />,
    [formId, handleSave]
  );

  return (
    isSaving,
    (
      <FormDialog
        ref={ref}
        renderTitle={title}
        renderForm={form}
        renderSuccessButtons={buttons}
        formId={formId}
        isSaving={isSaving}
        width='50.0%'
      />
    )
  );
});
