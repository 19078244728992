import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';

function LoadingImage({ url: ExternalUrl }) {
  const [imgUrl, setImgUrl] = useState(undefined);

  useEffect(() => {
    if (ExternalUrl) {
      setImgUrl(undefined);
      fetch(ExternalUrl)
        .then((response) => response.blob())
        .then((imageBlob) => setImgUrl(URL.createObjectURL(imageBlob)))
        .catch((err) => console.error(err));
    }
  }, [ExternalUrl]);

  return imgUrl ? (
    <img
      alt='Your signature'
      src={imgUrl}
      width={100}
      height={100}
      style={{ color: '#B9C5C5', fontFamily: 'Montserrat', fontSize: '10px' }}
    />
  ) : null;
}

export default LoadingImage;
