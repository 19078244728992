export const logInPageStyle = {
  padding: '1rem',
  height: '100vh',
  bgcolor: 'secondary.main',
  boxSizing: 'border-box',
  overflow: 'hidden',
};

export const iconsBarStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginInline: '5%',
  paddingTop: {
    xs: '10px',
    md: '55px',
  },
};

export const pageContentStyle = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  alignItems: {
    xs: 'start',
    sm: 'center',
  },
  justifyContent: 'center',
  gap: {
    xs: '5%',
    sm: '10%',
  },
  maxWidth: '1400px',
  marginInline: {
    xs: '5%',
    sm: 'auto',
  },
  height: {
    xs: 'calc(100% - 55px)',
    md: 'calc(100% - 100px)',
  },
};

export const mainHeaderStyle = {
  fontSize: {
    xs: '2.986rem',
    sm: '3.6rem',
    md: '5.2rem',
    lg: '6.2rem',
    xl: '7.5rem',
  },
  lineHeight: '1',
  maxWidth: {
    xs: '85%',
    sm: '50%',
  },
  fontWeight: '700',
};

export const signInBoxStyle = {
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  bgcolor: '#FFFFFF',
  paddingInline: '30px',
  paddingBottom: {
    xs: '40px',
    md: '80px',
  },
  width: {
    xs: '95%',
    sm: '50%',
    md: '30%',
  },
  '&::after': {
    content: '""',
    bgcolor: 'secondary.main',
    position: 'absolute',
    width: '42px',
    height: '10px',
    top: {
      xs: '48%',
      sm: '50%',
      md: '51%',
      lg: '48%',
    },
    right: '0',
  },
};

export const fontSize19style = {
  fontSize: {
    xs: '1rem',
    sm: '1.2rem',
  },
  lineHeight: '1',
  fontWeight: '700',
  marginBottom: {
    xs: '25px',
    md: '50px',
  },
  marginTop: {
    xs: '33px',
    md: '66px',
  },
};

export const fontSize28style = {
  fontSize: {
    xs: '1.44rem',
    sm: '1.728rem',
  },
  lineHeight: '100%',
  fontWeight: '700',
  marginBottom: '10px',
};

export const fontSize39style = {
  fontSize: {
    xs: '2.07rem',
    sm: '2.448rem',
  },
  lineHeight: '1',
  fontWeight: '700',
  marginBottom: {
    xs: '30px',
    md: '60px',
  },
};

export const signInButtonStyle = {
  height: '55px',
  bgcolor: '#E9F1FF',
  color: '#4285F4',
  fontSize: {
    xs: '0.833rem',
    sm: '1rem',
  },
  lineHeight: '1.5',
  textTransform: 'none',
  '&:hover': {
    bgcolor: 'background.default',
  },
};
