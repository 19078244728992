import { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { CourseList } from 'Components/Courses/CourseList/CourseList';

export function CoursePage({ courseDict, courses, pageInfo, errors, handleRefresh }) {
  const [sortedCourses, setSortedCourses] = useState([undefined, undefined, undefined]);

  useEffect(() => {
    if (courses && !!courseDict && courses.length > 0) {
      const items = courses.sort((a, b) => b.meeting_start_time < a.meeting_start_time);
      const split = items.reduce(
        (acc, course) => {
          [0, 1, 2].forEach((index) => {
            if (courseDict[index].predicate(course)) {
              acc[index].push(course);
            }
          });
          return acc;
        },
        [[], [], []]
      );
      setSortedCourses(split);
    } else {
      setSortedCourses([undefined, undefined, undefined]);
    }
  }, [courses, courseDict]);

  return (
    <Box sx={{ minHeight: 'calc(100vh - 90px)' }}>
      <Box p='80px' pb='0px' display='flex' gap='1em'>
        <Box>
          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '33px' }}>
            {pageInfo.emoji}
          </Typography>
        </Box>
        <Box>
          <Box>
            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '33px' }}>
              {pageInfo.title}
            </Typography>
          </Box>
          <Box sx={{ pb: 0, pt: '30px' }}>
            <Typography
              sx={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '16px' }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {pageInfo.subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <CourseList items={sortedCourses} title={courseDict} errors={errors} handleRefresh={handleRefresh} />
      </Box>
    </Box>
  );
}
