import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Helmet } from 'react-helmet';
import { Amplify } from '@aws-amplify/core';
import { BeeplomaTheme } from './theme/BeeplomaTheme';
import { Router } from './Router';
import { AuthProvider } from './providers/auth/provider';
import { UserDataProvider } from './providers/user/provider';
import { Client } from './api/client/Client';
import ReactQueryProvider from './api/config/ReactQueryProvider';

function App({ config }) {
  Amplify.configure({
    Auth: {
      identityPoolId: config.COGNITO_IDENTITY_POOL_ID,
      region: config.AWS_REGION,
      identityPoolRegion: config.AWS_REGION,
      userPoolId: config.COGNITO_USER_POOL_ID,
      userPoolWebClientId: config.COGNITO_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        domain: config.COGNITO_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: config.REDIRECT_SIGN_IN,
        redirectSignOut: config.REDIRECT_SIGN_OUT,
        responseType: 'code',
      },
    },
  });

  Client.setupClient(config.API_URL);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={BeeplomaTheme}>
          <ReactQueryProvider>
            <AuthProvider>
              <Helmet>
                <meta name='viewport' content='width=device-width, initial-scale=1.0, viewport-fit=cover' />
              </Helmet>
              <Router />
            </AuthProvider>
          </ReactQueryProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
}

export default App;
