import { useSnackbar } from 'notistack';

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export function SnackbarUtilsConfigurator() {
  setUseSnackbarRef(useSnackbar());
  return null;
}

export const SnackbarUtils = {
  success(msg, options = {}) {
    return this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg, options = {}) {
    return this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg, options = {}) {
    return this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg, options = {}) {
    return this.toast(msg, { ...options, variant: 'error' });
  },
  toast(msg, options = {}) {
    const finalOptions = {
      variant: 'default',
      ...options,
    };
    useSnackbarRef.enqueueSnackbar(msg, { ...finalOptions });
  },
};
