import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

export function DiplomaMain() {
  return (
    <Box p={2}>
      <Box mb={2} mt={1}>
        <Typography variant='h5'>Diploma instructions</Typography>
      </Box>
      <Box mb={2} mt={1}>
        <Typography variant='body1'>
          Nulla ante lectus, gravida sit amet justo et, porttitor varius odio. Proin eget elementum purus. Proin gravida
          dapibus eros ut suscipit. Nulla aliquam neque a dapibus semper. Integer elementum id nulla sed laoreet.
          Integer ac arcu eget metus egestas luctus sed non purus.
        </Typography>
      </Box>
      <Box mb={3} mt={2}>
        <Divider />
      </Box>
    </Box>
  );
}
