import Box from '../../../Components/Box/Box';
import Typography from '../../../Components/Typography/Typography';
import styles from './DiplomaValid.module.css';

function DiplomaValid() {
  return (
    <Box>
      <Typography variant='h3' align='center' className={styles.title}>
        Everythings went OK!
      </Typography>
    </Box>
  );
}

export default DiplomaValid;
