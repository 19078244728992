import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { BeeplomaTheme } from '../../../theme/BeeplomaTheme';

export function DiplomaDetails({ diploma }) {
  return (
    <Box textAlign='center' display='flex' gap={3} alignItem='center' justifyContent='center' flexDirection='column'>
      <Typography variant='h2' fontWeight='bold'>
        Certificate
      </Typography>
      <Typography textTransform='uppercase' variant='h5' fontWeight='normal'>
        of completion
      </Typography>
      <Typography textTransform='uppercase' variant='h4' fontWeight='500'>
        <Box display='inline-block' backgroundColor={BeeplomaTheme.palette.secondary.main} pr={1} pl={1}>
          {diploma?.course_title}
        </Box>
      </Typography>
      <Typography textTransform='uppercase' variant='h5' fontWeight='normal'>
        proudly presented to
      </Typography>
      <Typography textTransform='uppercase' variant='h4' fontWeight='500'>
        <Box display='inline-block' backgroundColor={BeeplomaTheme.palette.secondary.main} pr={1} pl={1}>
          {diploma?.student_name}
        </Box>
      </Typography>
      <Box mt={8} display='flex' flexBasis='100%'>
        <Box mr={2} display='flex' flexBasis='50%' alignItems='center' justifyContent='center' flexDirection='column'>
          <Typography variant='caption' fontWeight='500'>
            {dayjs(diploma?.course_end_date).format('L')}
          </Typography>
          <Box mt={1} width='100%' maxWidth='200px' borderTop='1px solid black'>
            <Typography variant='caption' fontWeight='500'>
              date
            </Typography>
          </Box>
        </Box>
        <Box ml={2} display='flex' flexBasis='50%' alignItems='center' justifyContent='center' flexDirection='column'>
          <Typography variant='caption' fontWeight='500'>
            {diploma?.instructor_name}
          </Typography>
          <Box mt={1} width='100%' maxWidth='200px' borderTop='1px solid black'>
            <Typography variant='caption' fontWeight='500'>
              signature
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={8} display='flex' flexBasis='100%' alignItems='center' justifyContent='center'>
        <Typography variant='caption' fontWeight='500'>
          Digital Forgery-proof verifiable and 100% digital certificates by #TechHive Academy at A4Bee
        </Typography>
      </Box>
    </Box>
  );
}
