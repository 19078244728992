import { useFormContext } from 'react-hook-form';
import React from 'react';

const FormInput = React.forwardRef(({ name, registerOptions = {}, ...inputProps }, ref) => {
  if (inputProps.required) {
    registerOptions.required = registerOptions.required ?? inputProps.required;
  }

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { ref: registerRef, ...noRefRegister } = register(name, registerOptions);
  return (
    <>
      <input
        ref={ref}
        {...inputProps}
        {...noRefRegister}
        onChange={(e) => {
          setValue(name, e?.target?.files[0] || null);
          registerOptions.onChange(e);
        }}
      />
      <p style={{ color: '#CF1248', fontFamily: 'Montserrat', fontSize: '0.75rem' }}>{errors[name]?.message}</p>
    </>
  );
});

export default FormInput;
