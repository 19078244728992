import { Checkbox, Typography, FormControlLabel, TextField, Paper, Skeleton } from '@mui/material';
import { useState, useCallback, useMemo, forwardRef, useEffect } from 'react';
import { Box } from '@mui/system';
import { useFinishCourse, useGetCourseDetails } from 'hooks/courses';
import { useUserData } from 'providers/user';
import FormDialog from 'Components/Form/FormDialog';
import CheckIcon from '@mui/icons-material/Check';
import ActionButton, { actionButtonIconStyle } from 'Components/Button/ActionButton';

export const HandleFinishCourse = forwardRef(({ courseId }, ref) => {
  const [wasOpened, setWasOpened] = useState(false);
  const { courseDetails, isLoading: detailsLoading, refetchDetails } = useGetCourseDetails(courseId);
  const [searchValue, setSearchValue] = useState('');
  const [selectedParticipantIds, setSelectedParticipantIds] = useState([]);

  const handleOpen = useCallback(() => {
    if (!wasOpened) {
      if (!courseDetails && !detailsLoading) {
        refetchDetails();
      }
      setWasOpened(true);
    }
  }, [courseDetails, detailsLoading]);
  const {
    state: { users },
  } = useUserData();
  const onFinishCourseSuccess = () => {
    if (ref.current) {
      ref.current.close();
    }
  };
  const { finishCourse, isLoading: isSaving } = useFinishCourse({
    onSuccess: onFinishCourseSuccess,
  });

  const handleFinish = () => {
    finishCourse({
      courseId,
      participants: selectedParticipantIds,
    });
  };

  const handleChange = (_, value) => {
    setSelectedParticipantIds((participantIds) =>
      !participantIds.includes(value) ? [...participantIds, value] : participantIds.filter((id) => id !== value)
    );
  };

  const handleSelectAll = useCallback(() => {
    setSelectedParticipantIds((participantIds) =>
      courseDetails?.participants.length === participantIds.length
        ? []
        : courseDetails?.participants?.map((participant) => participant.participant_id)
    );
  }, [courseDetails?.participants]);

  const handleSearch = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const participantData = useMemo(() => {
    const mapped = courseDetails?.participants?.map((participant) =>
      users.find((u) => u?.id === participant.participant_id)
    );
    return mapped;
  }, [courseDetails, users]);

  const title = useCallback(
    () => (
      <>
        <Typography variant='h6bold'>
          {participantData
            ? 'To finish course and generate diplomas for participants you need to check the attendance list and select present participants on the list below. '
            : 'Loading participant data...'}
        </Typography>
        <Box sx={{ pt: 1 }}>
          <TextField
            onChange={handleSearch}
            label={`Quantity: ${selectedParticipantIds.length}`}
            placeholder='Select...'
            disabled={!participantData || participantData.length === 0}
            variant='outlined'
            fullWidth
            sx={{ marginTop: '1rem' }}
          />
          <Box sx={{ marginLeft: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    participantData?.length !== undefined &&
                    selectedParticipantIds !== 0 &&
                    participantData.length === selectedParticipantIds.length
                  }
                />
              }
              onChange={handleSelectAll}
              label='SELECT ALL'
            />
          </Box>
        </Box>
      </>
    ),
    [participantData, selectedParticipantIds, handleSearch, handleSelectAll]
  );

  const renderParticipants = useCallback(
    (participantData) => {
      if (!participantData) {
        return (
          <Box sx={{ maxHeight: '100px', marginLeft: 2, paddingBlock: 2, display: 'flex', gap: '1em' }}>
            <Skeleton width='24px' height='24px' sx={{ transform: 'none' }} />
            <Typography variant='regular'>
              <Skeleton width='200px' />
            </Typography>
          </Box>
        );
      }
      if (participantData.length === 0) {
        return (
          <Box sx={{ maxHeight: '100px', marginLeft: 2, paddingBlock: 2 }}>
            <Typography component='h6'>This course doesn't have any participants</Typography>
          </Box>
        );
      }
      return participantData
        .filter((item) => `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchValue.toLowerCase()))
        .map((participant) => (
          <Box sx={{ maxHeight: '100px', marginLeft: 2 }} key={`formControlLabel_${participant.id}`}>
            <FormControlLabel
              control={<Checkbox checked={selectedParticipantIds.includes(participant.id)} />}
              onChange={(e) => {
                handleChange(e, participant.id);
              }}
              label={`${participant.first_name} ${participant.last_name}`}
            />
          </Box>
        ));
    },
    [selectedParticipantIds, searchValue]
  );

  const form = () => (
    <Box>
      <Paper sx={{ maxHeight: 300, overflow: 'auto' }}>{renderParticipants(participantData)}</Paper>
    </Box>
  );

  return (
    <FormDialog
      ref={ref}
      width='25%'
      renderForm={form}
      renderTitle={title}
      renderSuccessButtons={() => (
        <ActionButton
          variant='save'
          type='submit'
          loading={detailsLoading || isSaving}
          disabled={isSaving}
          onClick={handleFinish}
          icon={<CheckIcon sx={actionButtonIconStyle} />}
          text='Finish course'
        />
      )}
      onOpen={handleOpen}
    />
  );
});
