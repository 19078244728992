import { useState, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/system';
import { TextField, Autocomplete, IconButton } from '@mui/material';
import { useUserData } from 'providers/user';
import { useGetCoursesFilters } from 'hooks/courses/useGetCoursesFilters';
import { useGetAllIsInstructor } from 'hooks/users/useGetAllIsInstructor';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { courseStatuses } from 'constants/course';
import ClearIcon from '@mui/icons-material/Clear';
import FiltrationPagination from './FiltrationPagination';
import FiltrationHeader from './FiltrationHeader';
import FiltrationList from './FiltrationList';
import { CssTextField, filtrationListStyle } from './style';
import { locale } from '../../../constants/global';
import { GetUserView, userViewType } from '../../../utils/GetUserView';

function FiltrationPage() {
  const { pathname } = useLocation();
  const userView = useMemo(() => GetUserView(pathname), [pathname]);
  const [courseData, setCourseData] = useState([]);
  const [errors, setErrors] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [placeOptions, setPlaceOptions] = useState([]);
  const [instructorOptions, setInstructorOptions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [sortedBy, setSortedBy] = useState('desc');
  const [nameFilter, setNameFilter] = useState();
  const [tagsFilter, setTagsFilter] = useState();
  const [startDateFilter, setStartDateFilter] = useState();
  const [endDateFilter, setEndDateFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [timeFilter, setTimeFilter] = useState();
  const [placeFilter, setPlaceFilter] = useState();
  const [instructorFilter, setInstructorFilter] = useState();
  const itemsPerPage = 10;

  const filterParams = {
    page: pageNumber,
    size: itemsPerPage,
    order: sortedBy,
    order_by: null,
    name: nameFilter,
    tag: tagsFilter,
    meeting_start_time: startDateFilter,
    meeting_end_time: endDateFilter,
    place_id: placeFilter,
    instructor_id: instructorFilter,
    user_id: null,
    status: statusFilter,
    date_state: timeFilter,
  };

  const {
    courses,
    error: coursesError,
    isLoading: coursesLoading,
    refetch: refetchCourses,
  } = useGetCoursesFilters(filterParams);

  const {
    state: { places, tags },
  } = useUserData();

  const { users: instructors } = useGetAllIsInstructor();

  const handleSetPageNumber = (value) => setPageNumber(value);

  useEffect(() => {
    const result = [];
    if (!coursesLoading) {
      if (courses) {
        courses?.items?.map((course) => result.push(course));
      }
      setCourseData(() => [...result]);
      setPageCount(Math.ceil(courses.total / itemsPerPage));
    }
  }, [courses, coursesLoading]);

  useEffect(() => {
    const placesData = [];
    const tagsData = [];
    const instructorsData = [];
    if (places) {
      places?.map((place) => placesData.push(place));
    }
    if (tags) {
      tags?.map((tag) => tagsData.push(tag.name));
    }
    if (instructors) {
      instructors?.items?.map((instructor) => instructorsData.push(instructor));
    }
    setPlaceOptions(placesData);
    setTagOptions(tagsData);
    setInstructorOptions(instructorsData);
  }, [places, tags, instructors]);

  useEffect(() => {
    setErrors(!!coursesError);
  }, [coursesError]);

  useEffect(() => {
    refetchCourses();
  }, [
    pageNumber,
    sortedBy,
    nameFilter,
    tagsFilter,
    startDateFilter,
    endDateFilter,
    placeFilter,
    instructorFilter,
    statusFilter,
    timeFilter,
  ]);

  return (
    <Box>
      <FiltrationHeader />
      <Box sx={filtrationListStyle}>
        <Box sx={{ display: 'flex', gap: '20px', marginBlock: '4rem' }}>
          <IconButton onClick={() => setSortedBy((prev) => (prev === 'asc' ? 'desc' : 'asc'))}>
            <SwapVertIcon />
          </IconButton>
          <Autocomplete
            options={[]}
            freeSolo
            value={nameFilter || ''}
            sx={{ width: '200px' }}
            variant='outlined'
            size='small'
            onKeyPress={(e, value) => {
              if (e.key === 'Enter') {
                setNameFilter(e.target.value);
              }
            }}
            onChange={(event, newValue, reason) => {
              if (reason === 'clear') setNameFilter();
              setPageNumber(1);
            }}
            renderInput={(params) => <TextField {...params} label='Search' />}
          />
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
              <DatePicker
                label='From'
                clearable
                value={startDateFilter || ''}
                disabled={timeFilter === 'upcoming' || timeFilter === 'past'}
                onChange={(newValue) => {
                  newValue && dayjs(newValue).isValid()
                    ? setStartDateFilter(dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss.SSS'))
                    : setStartDateFilter();
                  setPageNumber(1);
                }}
                renderInput={(params) => (
                  <CssTextField variant='outlined' size='small' sx={{ width: '150px' }} {...params} />
                )}
              />
              <DatePicker
                label='To'
                value={endDateFilter || ''}
                disabled={timeFilter}
                onChange={(newValue) => {
                  newValue && dayjs(newValue).isValid()
                    ? setEndDateFilter(dayjs(newValue).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'))
                    : setEndDateFilter();
                  setPageNumber(1);
                }}
                renderInput={(params) => (
                  <CssTextField variant='outlined' size='small' sx={{ width: '150px' }} {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Autocomplete
            blurOnSelect
            multiple
            value={tagsFilter || []}
            limitTags={1}
            options={tagOptions ?? []}
            sx={{ width: '320px', maxHeight: '40px' }}
            variant='outlined'
            size='small'
            getOptionDisabled={(option) => tagsFilter?.length >= 3}
            onChange={(event, newValue, reason) => {
              reason === 'clear' ? setTagsFilter() : setTagsFilter(newValue);
              setPageNumber(1);
            }}
            renderInput={(params) => <TextField {...params} label='Topic' />}
          />
          {userView === userViewType.admin ? (
            <Autocomplete
              blurOnSelect
              value={statusFilter || ''}
              options={['published', 'draft']}
              sx={{ width: '200px' }}
              variant='outlined'
              size='small'
              onChange={(event, newValue, reason) => {
                reason === 'clear' ? setStatusFilter() : setStatusFilter(newValue);
                setPageNumber(1);
              }}
              renderInput={(params) => <TextField {...params} label='State' />}
            />
          ) : null}
          <Autocomplete
            blurOnSelect
            options={['past', 'upcoming']}
            value={timeFilter || ''}
            disabled={startDateFilter || endDateFilter}
            sx={{ width: '200px' }}
            variant='outlined'
            size='small'
            onChange={(event, newValue, reason) => {
              reason === 'clear' ? setTimeFilter() : setTimeFilter(newValue);
              setPageNumber(1);
            }}
            renderInput={(params) => <TextField {...params} label='Time' />}
          />
          <Autocomplete
            blurOnSelect
            options={placeOptions ?? []}
            sx={{ width: '200px' }}
            getOptionLabel={(option) => `${option.name}`}
            variant='outlined'
            size='small'
            onChange={(event, newValue, reason) => {
              reason === 'clear' ? setPlaceFilter() : setPlaceFilter(newValue.id);
              setPageNumber(1);
            }}
            renderInput={(params) => <TextField {...params} label='Place' />}
          />
          <Autocomplete
            blurOnSelect
            options={instructorOptions ?? []}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            sx={{ width: '200px' }}
            variant='outlined'
            size='small'
            onChange={(event, newValue, reason) => {
              reason === 'clear' ? setInstructorFilter() : setInstructorFilter(newValue.id);
              setPageNumber(1);
            }}
            renderInput={(params) => <TextField {...params} label='Instructor' />}
          />
          <IconButton
            onClick={() => {
              setPageNumber(1);
              setSortedBy('desc');
              setNameFilter();
              setStartDateFilter();
              setEndDateFilter();
              setTagsFilter();
              setPlaceFilter();
              setInstructorFilter();
              setStatusFilter();
              setTimeFilter();
            }}
          >
            <FilterAltOffIcon />
          </IconButton>
        </Box>
        <FiltrationList
          userView={userView}
          courses={courseData}
          errors={errors}
          coursesLoading={coursesLoading}
          handleRefresh={() => {
            setCourseData([]);
            refetchCourses();
          }}
        />
      </Box>
      <FiltrationPagination
        pageCount={pageCount}
        pageNumber={pageNumber}
        handleSetPageNumber={handleSetPageNumber}
        coursesLoading={coursesLoading}
        courses={courseData}
      />
    </Box>
  );
}

export default FiltrationPage;
