import { useQuery } from 'react-query';
import { PlacesController } from '../../api/controller/places/PlacesController';
import { GET_PLACES_ALL } from '../queries';

export const useGetAll = () => {
  const { data: places, error, isLoading } = useQuery([GET_PLACES_ALL], () => PlacesController.getAll());

  return {
    places,
    error,
    isLoading,
  };
};
