const config = {};
export default config;

async function load() {
  if (process.env.NODE_ENV === 'production') {
    return fetch('/env.json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        for (const prop in config) {
          if (Object.prototype.hasOwnProperty.call(config, prop)) {
            delete config[prop];
          }
        }

        for (const prop_1 in data) {
          if (Object.prototype.hasOwnProperty.call(data, prop_1)) {
            config[prop_1] = data[prop_1];
          }
        }

        return config;
      })
      .catch((err) => console.error(err));
  }

  config.COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
  config.AWS_REGION = process.env.REACT_APP_AWS_REGION;
  config.COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
  config.COGNITO_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;
  config.COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
  config.REDIRECT_SIGN_IN = process.env.REACT_APP_REDIRECT_SIGN_IN;
  config.REDIRECT_SIGN_OUT = process.env.REACT_APP_REDIRECT_SIGN_OUT;
  config.API_URL = process.env.REACT_APP_API_URL;

  return config;
}

export { load };
