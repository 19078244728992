import { useMutation, useQueryClient } from 'react-query';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_COURSES, GET_MY_COURSES } from '../queries';

const nullFoo = () => {};
const nullObj = {
  onSuccess: nullFoo,
  onError: nullFoo,
};

export const useDeleteCourse = ({ onSuccess = nullFoo, onError = nullFoo } = nullObj) => {
  const queryClient = useQueryClient();

  const { mutate, error, isLoading } = useMutation((payload) => CoursesController.deleteCourse(payload), {
    onSuccess: () => {
      queryClient.refetchQueries([GET_COURSES]);
      queryClient.refetchQueries([GET_MY_COURSES]);

      if (onSuccess) {
        onSuccess();
      }
    },
    onError,
  });

  return {
    deleteCourse: mutate,
    error,
    isLoading,
  };
};
