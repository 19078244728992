import { Pagination, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useEffect, useState } from 'react';
import { InstructorsListItem } from './InstructorsListItem';
import { skeletonStyle } from './style';
import { UsersController } from '../../api/controller/users/UsersController';

export function InstructorsList() {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(0);
  const [users, setUsers] = useState({});
  const usersPerPage = 8;

  useEffect(() => {
    setLoading(true);
    UsersController.getAllIsInstructor(pageNumber, usersPerPage).then((data) => {
      setUsers(data.items);
      setPageCount(Math.ceil(data.total / usersPerPage));
      setLoading(false);
    });
  }, [pageNumber]);

  return (
    <Box sx={{ marginTop: '7rem' }}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 600: 2, 900: 3, 1100: 4 }}>
        {loading ? (
          <Masonry>
            {[...Array(usersPerPage)].map((e, i) => (
              <Skeleton key={i} variant='circular' animation='wave' sx={skeletonStyle} />
            ))}
          </Masonry>
        ) : (
          <Masonry>
            {users !== undefined &&
              (users.length > 0 ? (
                users.map((user) => <InstructorsListItem user={user} key={user.id} />)
              ) : (
                <Box sx={{ p: 2 }}>
                  <Typography variant='regular'>There are no instructors</Typography>
                </Box>
              ))}
          </Masonry>
        )}
      </ResponsiveMasonry>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {loading ? (
          <Skeleton sx={{ width: '250px', height: '3rem' }} animation='wave' />
        ) : (
          <Pagination
            count={pageCount}
            defaultPage={1}
            page={pageNumber}
            size='large'
            onChange={(event, value) => setPageNumber(value)}
          />
        )}
      </Box>
    </Box>
  );
}
