import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Remove from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DoneIcon from '@mui/icons-material/DoneOutline';
import { styled } from '@mui/styles';
import { courseStates } from 'constants/course';
import { Skeleton } from '@mui/material';

const getColourFromState = (courseState) => {
  switch (courseState) {
    case courseStates.unregistered:
    case courseStates.admin:
      return {
        action: 'secondary',
        card: 'lightGrey',
        details: 'lightGrey',
        hover: 'secondary',
      };
    case courseStates.owned:
      return {
        action: 'darkBlue',
        card: 'blue',
        details: 'blue',
      };
    case courseStates.draft:
      return {
        action: 'darkGrey',
        card: 'lightGrey',
        details: 'lightGrey',
      };
    case courseStates.isNotFinished:
      return {
        action: 'secondary',
        card: 'raspberry',
        details: 'raspberry',
        hover: 'secondary',
      };
    default:
      return {
        action: 'grey',
        card: 'lightGrey',
        details: 'lightGrey',
        hover: 'grey',
      };
  }
};

function StateIcon({ courseState }) {
  switch (courseState) {
    case courseStates.unregistered:
    case courseStates.admin:
      return <AddIcon id='add-icon' />;
    case courseStates.owned:
      return <DoneIcon id='done-icon' />;
    case courseStates.draft:
      return <EditIcon id='edit-icon' />;
    default:
      return <Remove id='remove-icon' />;
  }
}

const alignment = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const button = {
  border: '2px 2px 0 0 solid',
  position: 'absolute',
  right: '0',
  padding: '7px',
  maxWidth: '40px',
  maxHeight: '40px',
  minWidth: '40px',
  minHeight: '40px',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon, & .MuiSvgIcon-root': {
    fontSize: '32px',
    margin: 0,
    '& > *': {
      fontSize: '32px',
    },
  },
};

const relative = {
  position: 'relative',
  borderRadius: '10px',
  width: 'max-content',
  maxWidth: 'auto',
  padding: '7px 20px',
  marginLeft: 'auto',
};

const topButton = {
  ...button,
  ...alignment,
  borderRadius: '0px 10px 0px 0px',
  top: '0',
};

export const CourseCard = styled(({ courseState, children, ...props }) => (
  <button type='button' {...props}>
    {children}
  </button>
))(({ theme, courseState }) => {
  const color = getColourFromState(courseState);
  const hoverCol = theme.palette[color[color.hover ? 'hover' : 'card']];
  return {
    border: `2px solid ${theme.palette[color.card].main}`,
    transition: theme.transitions.create(['border-color', 'transform']),
    display: 'flex',
    width: '100%',
    textAlign: 'start',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '10px',
    position: 'relative',
    minHeight: '80px',
    boxSizing: 'border-box',
    padding: '13px',
    '&:not(:only-child)': {
      marginBottom: '1em',
    },
    '&:not(:disabled)': {
      '&:where(:hover, :focus-visible, :focus-within)': {
        borderColor: hoverCol.dark,
        filter: `drop-shadow(0px 0px 20px ${hoverCol.dark}99)`,
        '& button.details-button': {
          backgroundColor: hoverCol.dark,
          color: hoverCol.contrastText,
        },
      },
      cursor: 'pointer',
    },
  };
});

export const ButtonsSkeleton = styled((props) => <Skeleton {...props} animation='wave' height='80px' width='40px' />)(
  () => ({
    position: 'absolute',
    right: '0',
    top: '0',
    borderRadius: '0 10px 10px 0',
    transform: 'none',
  })
);

export const ActionButton = styled(({ text, courseState, fullButton, ...props }) => (
  <LoadingButton {...props} startIcon={<StateIcon courseState={courseState} />}>
    {text}
  </LoadingButton>
))(({ theme, fullButton, courseState }) => {
  const color = theme.palette[getColourFromState(courseState).action];
  return {
    ...topButton,
    ...(fullButton ? relative : {}),
    background: color.main,
    color: color?.contrastText ?? 'black',
    '&:hover, &:focus-visible': {
      backgroundColor: color.dark,
    },
  };
});

export const DetailsButton = styled(({ isAlone, className, courseState, ...props }) => (
  <Button {...props} className={`details-button ${className}`} id='more-icon'>
    <MoreVertIcon sx={{ fontSize: button.fontSize }} />
  </Button>
))(({ theme, isAlone, courseState }) => {
  const color = getColourFromState(courseState);
  const mainColor = theme.palette[color.details];
  const hoverCol = color.hover ? theme.palette[color.hover] : mainColor;
  return {
    ...button,
    ...alignment,
    background: mainColor.main,
    borderRadius: `${isAlone ? '10px' : '0px'} 0px 10px 0px`,
    color: mainColor?.contrastText ?? 'black',
    bottom: '0',
    '&:hover, &:focus-visible': {
      backgroundColor: hoverCol.dark,
      color: hoverCol.contrastText,
      boxShadow: 'none',
    },
  };
});

export const tagDrawerStyle = {
  color: 'grey.main',
  fontWeight: '700',
  fontSize: '11px',
  paddingInline: '5px',
};

export const tagListStyle = {
  bgcolor: 'blue.accent',
  color: 'primary.contrastText',
  fontWeight: '700',
  fontSize: '11px',
  paddingInline: '5px',
  display: {
    md: 'none',
    lg: 'inherit',
  },
};

export const draftTagListStyle = {
  bgcolor: 'black',
  color: 'primary.contrastText',
  fontWeight: '700',
  fontSize: '11px',
  display: {
    md: 'none',
    lg: 'inherit',
  },
};

export const truncateStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
};
