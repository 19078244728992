import 'dayjs/locale/pl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormSelect from 'Components/Form/Inputs/FormSelect';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useGetAllWithSignature } from 'hooks/users/useGetAllWithSignature';
import FormContainer from 'Components/Form/FormContainer';
import { inputStyle } from 'Components/Form/Inputs/styles';
import { FormTextField, FormAutocomplete, FormDateTimePicker } from 'Components/Form/Inputs';
import { useUserData } from 'providers/user';
import { courseStatuses } from 'constants/course';
import { Box } from '@mui/system';
import { createFilterOptions } from '@mui/material/Autocomplete';
import FormSelectParticipants from 'Components/Form/Inputs/FormSelectParticipants';
import { locale } from '../../../constants/global';
import { useHandleCourseResolver } from './handleCourseResolver';
import { userViewType } from '../../../utils/GetUserView';

const filter = createFilterOptions();

export function HandleCourseForm({ course, id, onSubmit, onChange, view }) {
  const { users } = useGetAllWithSignature();
  const {
    state: { places, users: participants, tags },
  } = useUserData();

  const [placeOptions, setPlaceOptions] = useState([]);
  const [meetingStartMax, setMeetingStartMax] = useState(dayjs().add(24, 'months').toDate());
  const [meetingEndMin, setMeetingEndMin] = useState(dayjs().add(1, 'day').toDate());
  const { resolver } = useHandleCourseResolver(places);

  useEffect(() => {
    setPlaceOptions(places.sort((a, b) => a.type.localeCompare(b.type)));
  }, [places]);

  const defaultValues = {
    name: '',
    instructor_id: null,
    description: '',
    meeting_start_time: null,
    meeting_end_time: null,
    no_places: 0,
    no_places_online: 0,
    place_ids: [],
    hours_to_prepare: 0,
    type: '',
    participant_ids: [],
    participants: [],
    tags: [],
  };

  return (
    <FormContainer
      formProps={{ id }}
      onSuccess={onSubmit}
      useFormProps={{ resolver, defaultValues }}
      onChange={onChange}
      loadValues={course}
    >
      <FormTextField
        name='name'
        inputProps={{ maxLength: 128 }}
        sx={inputStyle}
        label='Course name'
        variant='outlined'
        size='small'
        disabled={course?.is_finished && view === userViewType.user}
      />
      <FormAutocomplete
        name='instructor_id'
        options={users?.items?.map((x) => x) ?? []}
        textFieldProps={{
          label: 'Instructor',
          variant: 'outlined',
        }}
        autocompleteProps={{
          getOptionLabel: (option) => `${option.first_name} ${option.last_name}`,
          isOptionEqualToValue: (option, value) => option?.id === value?.id,
          sx: inputStyle,
          size: 'small',
        }}
        store='id'
        disabled={view === userViewType.user}
      />
      <FormAutocomplete
        name='place_ids'
        options={placeOptions}
        textFieldProps={{
          label: 'Place',
          variant: 'outlined',
        }}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          isOptionEqualToValue: (option, value) => option?.id === value || option?.id === value.id,
          sx: inputStyle,
          size: 'small',
          groupBy: (option) => option.type,
        }}
        multiple
        store='id'
        disableOptionBasedOnValue={(option, value) =>
          value.some((placeId) => placeOptions?.find((p) => p.id === placeId)?.type === 'onsite') &&
          !value.includes(option.id) &&
          option.type !== 'online' &&
          option.type !== 'external'
        }
        disabled={course?.is_finished && view === userViewType.user}
      />
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <FormDateTimePicker
            name='meeting_start_time'
            label='Start date'
            size='small'
            minDate={view === userViewType.admin ? null : dayjs()}
            maxDate={dayjs(meetingStartMax)}
            minutesStep={15}
            inputProps={{
              sx: inputStyle,
              size: 'small',
              variant: 'outlined',
              required: true,
            }}
            onChange={(value) => {
              setMeetingEndMin(value);
            }}
            disabled={course?.is_finished || (course?.is_finished && view === userViewType.user)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <FormDateTimePicker
            name='meeting_end_time'
            label='End date'
            size='small'
            minDate={dayjs(meetingEndMin)}
            minutesStep={15}
            inputProps={{
              sx: inputStyle,
              size: 'small',
              variant: 'outlined',
              required: true,
            }}
            onChange={(value) => {
              setMeetingStartMax(value);
            }}
            disabled={course?.is_finished || (course?.is_finished && view === userViewType.user)}
          />
        </LocalizationProvider>
      </Box>
      <FormTextField
        name='description'
        multiline
        sx={inputStyle}
        variant='outlined'
        label='Description'
        size='small'
        minRows={2}
        maxRows={8}
        inputProps={{ maxLength: 1000 }}
        disabled={course?.is_finished && view === userViewType.user}
      />
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <FormTextField
          name='no_places'
          inputProps={{ default: 0, min: 0, max: 250 }}
          type='number'
          sx={inputStyle}
          variant='outlined'
          label='Specify available seats'
          size='small'
          disabled={course?.is_finished && view === userViewType.user}
        />
        <FormTextField
          name='no_places_online'
          inputProps={{ default: 0, min: 0, max: 250 }}
          type='number'
          sx={inputStyle}
          variant='outlined'
          label='Specify available online seats'
          size='small'
          disabled={course?.is_finished && view === userViewType.user}
        />
      </Box>
      {course ? (
        <FormSelectParticipants
          coursePlaces={course?.place_ids}
          places={places}
          participants={participants}
          name='participants'
          disabled={course?.status === courseStatuses.draft || course?.id === undefined}
        />
      ) : null}
      <FormSelect
        name='type'
        sx={inputStyle}
        label='Type'
        variant='outlined'
        size='small'
        labelId='type_of_the_course'
        id='type_of_the_course'
        disabled={course?.is_finished && view === userViewType.user}
      >
        <MenuItem value='Lecture'>Lecture</MenuItem>
        <MenuItem value='Workshop'>Workshop</MenuItem>
      </FormSelect>
      <FormTextField
        name='hours_to_prepare'
        inputProps={{ min: 0, max: 168 }}
        type='number'
        sx={[inputStyle, { marginTop: '16px' }]}
        variant='outlined'
        label='Hours to prepare'
        size='small'
      />
      <FormTextField
        name='presentation_link'
        sx={inputStyle}
        label='Link to presentation'
        variant='outlined'
        size='small'
      />
      <FormTextField
        name='recording_link'
        sx={inputStyle}
        label='Link to performance record'
        variant='outlined'
        size='small'
      />
      <FormAutocomplete
        name='tags'
        options={tags ?? []}
        store='name'
        multiple
        freeSolo
        limitTags={3}
        limit={3}
        textFieldProps={{
          label: 'Choose a tag',
          variant: 'outlined',
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              name: inputValue,
              inputValue,
            });
          }
          return filtered;
        }}
        autocompleteProps={{
          getOptionLabel: (option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option?.inputValue) {
              return `Add "#${option.name}"`;
            }
            return `#${option?.name}`;
          },
          isOptionEqualToValue: (option, value) => option?.name === value?.name,
          sx: inputStyle,
          size: 'small',
        }}
      />
    </FormContainer>
  );
}
