export const container = {
  minHeight: 'calc(100vh - 90px)',
  maxWidth: '750px',
  overflow: 'hide',
  marginInline: {
    xs: '1rem',
    sm: '2rem',
    lg: '3rem',
    xl: '5rem',
  },
};
