import { Typography } from '@mui/material';

function AccessRestriction() {
  return (
    <div id='access-restriction'>
      <Typography>You don't have proper rights to access this page</Typography>
    </div>
  );
}

export default AccessRestriction;
