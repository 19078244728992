import { SnackbarUtils } from '../../utils/SnackbarUtils.js';
import { CLIENT } from '../config/Client.js';
import { errorMessage } from '../../constants/global';

const succesInfo = 'You action finished with success';

export class Client {
  static setupClient(url) {
    CLIENT.defaults.baseURL = url;
  }

  static async get(url, config, fullResponse) {
    return await CLIENT.get(url, config)
      .then((response) => (fullResponse ? response : response?.data))
      .catch((err) => {
        throw err;
      });
  }

  static async post(url, data, config) {
    return await CLIENT.post(url, data, config)
      .then((response) => response?.data)
      .then(() => SnackbarUtils.success(`${succesInfo}`))
      .catch((error) => {
        error.response?.data?.detail
          ? SnackbarUtils.error(
              error.response.data.detail[0].msg ? error.response.data.detail[0].msg : error.response.data.detail[0]
            )
          : SnackbarUtils.error(`${errorMessage}`);

        throw error;
      });
  }

  static async put(url, data, config) {
    return await CLIENT.put(url, data, config)
      .then((response) => response?.data)
      .then(() => SnackbarUtils.success(`${succesInfo}`))
      .catch((error) => {
        error.response?.data?.detail
          ? SnackbarUtils.error(
              error.response.data.detail[0].msg ? error.response.data.detail[0].msg : error.response.data.detail[0]
            )
          : SnackbarUtils.error(`${errorMessage}`);

        throw error;
      });
  }

  static async patch(url, data, config) {
    return await CLIENT.patch(url, data, config)
      .then((response) => response?.data)
      .catch((error) => {
        error.response?.data?.detail
          ? SnackbarUtils.error(
              error.response.data.detail[0].msg ? error.response.data.detail[0].msg : error.response.data.detail[0]
            )
          : SnackbarUtils.error(`${errorMessage}`);

        throw error;
      });
  }

  static async delete(url, config) {
    return await CLIENT.delete(url, config)
      .then((response) => response?.data)
      .then(() => SnackbarUtils.success(`${succesInfo}`))
      .catch((error) => {
        error.response?.data?.detail
          ? SnackbarUtils.error(
              error.response.data.detail[0].msg ? error.response.data.detail[0].msg : error.response.data.detail[0]
            )
          : SnackbarUtils.error(`${errorMessage}`);

        throw error;
      });
  }
}
