import { useAuth } from '../../providers/auth';
import AccessRestriction from '../AccessRestriction/AccessRestriction';

export const withRole = (roles, showError) => (Component) =>
  function (props) {
    const {
      state: { user },
    } = useAuth();

    if (user.roles.some((v) => roles.includes(v))) {
      return <Component {...props} />;
    }

    if (showError) {
      return <AccessRestriction />;
    }

    return null;
  };

export const withAdminRole = withRole(['admin'], false);
export const withInstructorRole = withRole(['instructor'], false);
export const withAdminOrInstructorRole = withRole(['instructor', 'admin'], false);

export const withAdminRoleAndError = withRole(['admin'], true);
export const withInstructorRoleAndError = withRole(['instructor'], true);
export const withAdminOrInstructorRoleAndError = withRole(['instructor', 'admin'], true);
