import { useQuery } from 'react-query';
import { UsersController } from '../../api/controller/users/UsersController';
import { GET_USERS_ALL } from '../queries';

export const useGetAll = () => {
  const { data: users, error, isLoading } = useQuery([GET_USERS_ALL], () => UsersController.getAll());

  return {
    users,
    error,
    isLoading,
  };
};
