import { Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useCallback, forwardRef, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FormDialog from 'Components/Form/FormDialog';
import { courseStatuses } from 'constants/course';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from 'dayjs';
import ActionButton, { actionButtonIconStyle } from 'Components/Button/ActionButton';
import { HandleCourseForm } from './HandleCourseForm';
import { GetUserView } from '../../../utils/GetUserView';

export const HandleCourse = forwardRef(
  ({ formId, onSubmit, isSaving, data, openDialog = false, setOpenDialog }, ref) => {
    const stateRef = useRef();

    const handleClose = () => {
      setOpenDialog(false);
    };

    const handleSubmit = (data, e) => {
      e.preventDefault();
      data?.meeting_start_time < dayjs() && data?.meeting_end_time < dayjs()
        ? (data.status = courseStatuses.published)
        : (data.status = stateRef.current);
      onSubmit(data, e);
    };

    const { pathname } = useLocation();
    const userView = useMemo(() => GetUserView(pathname), [pathname]);

    const title = useCallback(
      () => (
        <Box mb={2}>
          <Typography variant='h1bold'>{!data ? 'New Course' : 'Edit Course'}</Typography>
        </Box>
      ),
      [data]
    );

    const buttons = useCallback(
      () => (
        <>
          {(data?.status === courseStatuses.draft || !data) && (
            <ActionButton
              variant='draft'
              type='submit'
              form={formId}
              loading={isSaving}
              onClick={(e) => {
                stateRef.current = courseStatuses.draft;
              }}
              icon={<SaveIcon sx={actionButtonIconStyle} />}
              text='Save draft'
            />
          )}
          <ActionButton
            variant='save'
            type='submit'
            form={formId}
            loading={isSaving}
            onClick={(e) => {
              stateRef.current = courseStatuses.published;
            }}
            icon={<SaveIcon sx={actionButtonIconStyle} />}
            text='publish'
          />
        </>
      ),
      [formId, isSaving, data, stateRef]
    );

    const formData = useCallback(
      () => <HandleCourseForm id={formId} onSubmit={handleSubmit} course={data} view={userView} />,
      [onSubmit, formId, data]
    );

    return (
      <>
        <FormDialog
          ref={ref}
          renderTitle={title}
          renderForm={formData}
          renderSuccessButtons={buttons}
          formId={formId}
          isSaving={isSaving}
        />
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Course from the past</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Because you entered past dates into the course, we have published your course in "past courses". The
              course is finished, but you can still add participants.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ActionButton variant='save' text='OK' onClick={handleClose} />{' '}
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
