import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import { BeeplomaTheme } from 'theme/BeeplomaTheme';

export const InstructorItem = styled(({ children, ...props }) => <Box {...props}>{children}</Box>)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: '5.75rem',
  paddingInline: '10px',
  position: 'relative',
}));

export const InitialsAvatar = styled(({ children, ...props }) => <Typography {...props}>{children}</Typography>)(
  () => ({
    height: '6.8rem',
    width: '6.8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: BeeplomaTheme.palette.secondary.main,
    borderRadius: '50%',
    position: 'absolute',
    top: '-60px',
  })
);

export const InstructorDetailsBox = styled(({ children, ...props }) => <Box {...props}>{children}</Box>)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  border: '2px solid',
  borderColor: BeeplomaTheme.palette.lightGrey.main,
  borderRadius: '12px',
  width: '100%',
  paddingTop: '55px',
  paddingBottom: '35px',
}));

export const skeletonStyle = {
  width: '100px',
  height: '100px',
  marginBottom: '5.75rem',
  marginInline: '40%',
};
