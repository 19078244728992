import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { SnackbarProvider } from 'notistack';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { load } from './config';
import { SnackbarUtilsConfigurator } from './utils/SnackbarUtils';

load().then((config) => {
  ReactDOM.render(
    <React.StrictMode>
      <SnackbarProvider maxSnack={3}>
        <SnackbarUtilsConfigurator />
        <App config={config} />
      </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
