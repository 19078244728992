import { ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { listItemButtonPaddingStyle, listItemTextStyle, listItemWidthStyle } from '../style';

export default function MenuListItem({ children, text, color, onClick }) {
  return (
    <ListItemButton sx={[listItemButtonPaddingStyle, { color: `${color}` }]} onClick={onClick}>
      <ListItemIcon sx={listItemWidthStyle}>{children}</ListItemIcon>
      <Typography sx={listItemTextStyle}>{text}</Typography>
    </ListItemButton>
  );
}
