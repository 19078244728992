import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { HandleProfileForm } from 'Components/Profile/HandleProfileForm';
import { useUpdateMe } from 'hooks/users/useUpdateMe';
import Image from './LoadingImage';
import { iconButton, profileContainer, userDetailsLabelStyle } from './style';

export function HandleProfile({ myDetails }) {
  const [edit, setEdit] = useState(false);

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleSuccess = () => {
    handleCancel();
  };

  const { updateMe, isLoading: editSaving } = useUpdateMe({
    onSuccess: handleSuccess.bind(this),
  });

  const handleSave = (data) => {
    const { description, signature_key } = data;
    const formData = new FormData();
    formData.append('description', description || '');
    if (signature_key) {
      formData.append('signature_key', signature_key);
    }
    updateMe({ currentUser: formData });
  };

  return (
    <>
      {!edit && (
        <Box display='flex' gap='4rem' marginLeft='12.5rem'>
          <Box sx={profileContainer}>
            <Typography sx={userDetailsLabelStyle}>e-mail</Typography>
            <Typography>{myDetails.currentUser.email}</Typography>
            <Typography sx={userDetailsLabelStyle}>description</Typography>
            <Typography>{myDetails.currentUser.description}</Typography>
            <Typography sx={userDetailsLabelStyle}>signature</Typography>
            <Image url={myDetails.currentUser.signature_key} />
          </Box>
          <Box>
            <IconButton onClick={handleEditClick} sx={iconButton}>
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      {edit && (
        <HandleProfileForm
          formId={`edit_myprofile_${myDetails.currentUser.id}`}
          myDetails={myDetails}
          handleCancel={handleCancel}
          onSubmit={handleSave}
          isSaving={editSaving}
          data={{
            description: myDetails?.currentUser.description,
          }}
        />
      )}
    </>
  );
}
