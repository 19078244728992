import { Route, Routes } from 'react-router-dom';
import { DiplomaMain } from './diploma-main/DiplomaMain';
import DiplomaSuccess from './diploma-success/DiplomaSuccess';
import DiplomaValid from './diploma-validator/DiplomaValid';
import { DiplomaVerify } from './diploma-verify/DiplomaVerify';

export function Diploma({ user }) {
  return (
    <Routes>
      <Route path='/verify/:hash' element={<DiplomaVerify />} />
      <Route path='/success' element={<DiplomaSuccess />} />
      <Route path='/validator' element={<DiplomaValid />} />
      <Route path='/' element={<DiplomaMain />} />
    </Routes>
  );
}
