import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { errorMessage } from 'constants/global';
import { userViewType } from 'utils/GetUserView';
import { AdminCourseListItem, UserCourseListItem } from '../CourseListItem';
import { CourseListItem } from '../CourseListItem/CourseListItem';

function FiltrationList({ userView, courses, errors, coursesLoading, handleRefresh }) {
  const CourseListComponent = useMemo(
    () => (userView === userViewType.user ? UserCourseListItem : AdminCourseListItem),
    [userView]
  );

  return (
    <Box>
      {errors && (
        <Box sx={{ p: 2 }}>
          <Alert severity='error'>
            <>
              {errorMessage}
              <LoadingButton color='error' sx={{ height: '20px' }} onClick={handleRefresh}>
                <RefreshIcon />
                Refresh
              </LoadingButton>
            </>
          </Alert>
        </Box>
      )}
      {!errors && !coursesLoading && courses.length === 0 ? (
        <Typography variant='h3bold'>There is no courses...</Typography>
      ) : null}
      {!errors && coursesLoading
        ? [...Array(10)].map((e, i) => <CourseListItem key={i} />)
        : courses.map(
            (course) => !errors && <CourseListComponent courseDetails={course} key={`CourseListItem_${course.id}`} />
          )}
    </Box>
  );
}

export default FiltrationList;
