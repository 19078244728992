import { useQuery } from 'react-query';
import { UsersController } from '../../api/controller/users/UsersController';
import { GET_USERS_ALL_WITH_SIGNATURE } from '../queries';

export const useGetAllWithSignature = (pageNumber, itemsPerPage) => {
  const {
    data: users,
    error,
    isLoading,
  } = useQuery([GET_USERS_ALL_WITH_SIGNATURE, pageNumber, itemsPerPage], () =>
    UsersController.getAllWithSignature(pageNumber, itemsPerPage)
  );

  return {
    users,
    error,
    isLoading,
  };
};
