import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { container } from './style';

export function InstructorsToDo() {
  return (
    <Box sx={container}>
      <Typography variant='h3bold' sx={{ display: 'block', marginBlock: '100px' }}>
        👩‍🏫 To-do list for instructors
      </Typography>
      <Typography variant='h5bold'>❓ What should you determine before proposing a workshop?</Typography>
      <ol style={{ margin: '40px 0' }}>
        <li>
          <Typography variant='regularbold'>The exact purpose and scope of the workshop </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>Date and time of training</Typography>
        </li>
        <li>
          <Typography variant='regularbold'>Location (Warsaw, Wroclaw, online) </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>Time needed to prepare the workshop</Typography>
        </li>
        <li>
          <Typography variant='regularbold'>Possible limit of seats divided into places onsite and remote</Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Additional costs (necessity of delegation, buying materials, snacks, etc.)
          </Typography>
        </li>
      </ol>
      <Typography variant='regularbold' sx={{ marginBottom: 2, display: 'block' }}>
        Please remember that your course must be announced at least one or two weeks in advance, in order to allow for
        budget organization and enable interested users to enroll.{' '}
      </Typography>
      <Typography variant='regularbold' sx={{ marginBottom: 2, display: 'block' }}>
        {`To become an Instructor you need upload your signature in `}
        <Link href='https://beeploma.a4bee.com/myprofile' color='inherit' target='_blank'>
          MyProfile
        </Link>
        {` section.`}
      </Typography>
      <Typography variant='regularbold' sx={{ display: 'block', marginBottom: '60px' }}>
        {`The workshop can be submitted to Gabriela Lech (admin) via email `}(
        <Link href='mailto:gabriela.lech@a4bee.com'>gabriela.lech@a4bee.com</Link>).
      </Typography>
      <Typography variant='h5bold'>
        ❓ What do you need to remember when you create a training, after getting approval for its realization?
      </Typography>
      <ol style={{ margin: '40px 0' }}>
        <li>
          <Typography variant='regularbold'>Get approval of the budget from Gabriela Lech for the workshop</Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Create an agenda and define #hashtags for your course - to better understand the scope and field of the
            material. Send this details to admin.
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            After adding the training on Beeploma, an automatic email and chat notification will be sent to all company
            employees.
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>All interested users can assign to the course through Beeploma </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            You can also add individuals who have expressed their will to participate, or whose attendance is mandatory.{' '}
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>Submit delegation as soon as the training is approved</Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Notify buying needs at minimum one week before the training:
            <br />- in Warsaw to Natalia Iwańska (
            <Link href='mailto:natalia.iwanska@a4bee.com'>natalia.iwanska@a4bee.com</Link>)<br />- in Wroclaw to Jakub
            Kopeć (<Link href='mailto:jakub.kopec@a4bee.com'>jakub.kopec@a4bee.com</Link>)<br />
            The email should include the approved budget (by Gabriela Lech) for the training, the number and type of
            items to be bought, location and delivery time.
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Booking the{' '}
            <Link
              href='https://a4beewiki.atlassian.net/wiki/spaces/AO/pages/938868791/Booking+conference+room+WROCLAW'
              color='inherit'
              target='_blank'
            >
              room
            </Link>{' '}
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Creating a{' '}
            <Link
              href='https://docs.google.com/presentation/d/1zKl9MckARoDRjVpadsMehpL_yfAeyFuA3Kxa9wAKdbc/edit'
              color='inherit'
              target='_blank'
            >
              presentation
            </Link>{' '}
            and adding it to the Beeploma app
          </Typography>
        </li>
      </ol>
      <Typography variant='h5bold'>❓ What do you need to remember during and after the training? </Typography>
      <ol style={{ margin: '40px 0 150px' }}>
        <li>
          <Typography variant='regularbold'>Create an attendance list</Typography>
        </li>
        <li>
          <Typography variant='regularbold'>If the course is conducted online, please record a video</Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Add the recording to a{' '}
            <Link
              href='https://drive.google.com/drive/folders/1YX_OLaSgQ61U5lkW8Ox9-4M0CspbdtdT'
              color='inherit'
              target='_blank'
            >
              folder
            </Link>{' '}
            on google drive and add a link to the recording in the BeePloma app
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Finish the course in the Beeploma app - check the attendance list and select only those individuals who
            actually participated in the course.
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            After finishing the course, the diplomas for all selected participants will be generated and automatically
            sent
          </Typography>
        </li>
        <li>
          <Typography variant='regularbold'>
            Correctly log the time spent on preparing and conducting the training
            (Clockify:People&Culture:Learning:Training Development code)
          </Typography>
        </li>
      </ol>
    </Box>
  );
}
