import axios from 'axios';
import { Auth } from '@aws-amplify/auth';

export const DIPLOMAS_ENDPOINT = 'diplomas';
export const COURSES_ENDPOINT = 'courses';
export const USERS_ENDPOINT = 'users';
export const PLACES_ENDPOINT = 'places';
export const TAGS_ENDPOINT = 'tags';

export const CLIENT = axios.create();

CLIENT.interceptors.request.use(
  async (request) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      if (!token) throw new Error('Missing id token');

      if (token && request.headers) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      // eslint-disable-next-line no-empty
    } catch {}

    return request;
  },
  (error) => Promise.reject(error)
);

CLIENT.interceptors.response.use(
  (response) => {
    if (response.status === 202) {
      return CLIENT.request(response.config);
    }

    return response;
  },
  (error) => Promise.reject(error)
);
