import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

function FormSelect({ name, children, ...props }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { invalid, error } }) => (
        <FormControl fullWidth error={invalid}>
          <InputLabel sx={{ top: '-8px' }} id={props.labelId}>
            {props.label}
          </InputLabel>
          <Select
            {...field}
            {...props}
            sx={{ width: '100%' }}
            labelId={props.labelId}
            id={props.id}
            label={props.label}
            error={invalid}
          >
            {children}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default FormSelect;
