import regexifyString from 'regexify-string';
import dayjs from 'dayjs';
import { CoursePage } from 'Views/Courses/CoursePage';
import { useGetCourses } from 'hooks/courses';
import { useState, useEffect } from 'react';
import { courseStatuses } from 'constants/course';
import { BeeplomaTheme } from '../../theme/BeeplomaTheme';

const title = regexifyString({
  pattern: /\badmin\b/gim,
  decorator: (match, index) => (
    <span key={index} style={{ color: BeeplomaTheme.palette.raspberry.main }}>
      {match}
    </span>
  ),
  input: 'Hi, you are in admin panel!',
});

const subtitle = regexifyString({
  pattern: /\badmin\b/gim,
  decorator: (match, index) => (
    <span key={index} style={{ color: BeeplomaTheme.palette.raspberry.main }}>
      {match}
    </span>
  ),
  input: `You are admin, so you can create and manage courses.
    Let's create first course by clicking on “create new course” button!`,
});

const pageInfo = {
  emoji: '👋',
  title,
  subtitle,
};

export function AdminPage() {
  const {
    courses: publishedCourses,
    error: coursesError,
    isLoading: coursesLoading,
    refetch: refetchCourses,
  } = useGetCourses(courseStatuses.published);
  const {
    courses: draftCourses,
    error: draftCoursesError,
    isLoading: draftCoursesLoading,
    refetch: refetchDraftCourses,
  } = useGetCourses(courseStatuses.draft);
  const [courseData, setCourseData] = useState([]);
  const [errors, setErrors] = useState([false, false, false]);

  useEffect(() => {
    const result = [];
    if (!coursesLoading && !draftCoursesLoading) {
      if (publishedCourses) {
        publishedCourses?.items?.map((course) => {
          course.status = courseStatuses.published;
          return result.push(course);
        });
      }

      if (draftCourses) {
        draftCourses?.items?.map((course) => {
          course.status = courseStatuses.draft;
          return result.push(course);
        });
      }

      setCourseData(result);
    }
  }, [publishedCourses, draftCourses, coursesLoading, draftCoursesLoading]);

  useEffect(() => {
    setErrors([!!draftCoursesError, !!coursesError, !!coursesError]);
  }, [coursesError, draftCoursesError]);

  const coursesDict = [
    {
      color: BeeplomaTheme.palette.darkGrey,
      name: 'Drafts',
      predicate: (course) => course.status === courseStatuses.draft,
    },
    {
      color: BeeplomaTheme.palette.raspberry,
      name: 'Upcoming courses',
      predicate: (course) =>
        course.status === courseStatuses.published &&
        dayjs(course?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') >= dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    },
    {
      color: BeeplomaTheme.palette.raspberry,
      name: 'Past courses',
      predicate: (course) =>
        course.status === courseStatuses.published &&
        dayjs(course?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') < dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    },
  ];

  return (
    <CoursePage
      courses={courseData}
      courseDict={coursesDict}
      pageInfo={pageInfo}
      errors={errors}
      handleRefresh={() => {
        setCourseData([]);
        refetchDraftCourses() && refetchCourses();
      }}
    />
  );
}
