import { Box, Typography } from '@mui/material';
import styles from './DiplomaSuccess.module.css';

function DiplomaSuccess() {
  return (
    <Box>
      <Typography variant='h3' align='center' className={styles.title}>
        OK!
      </Typography>
    </Box>
  );
}

export default DiplomaSuccess;
