import { Pagination, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

function FiltrationPagination({ pageCount, pageNumber, handleSetPageNumber, coursesLoading, courses }) {
  const pagination =
    courses.length === 0 ? null : (
      <Pagination
        count={pageCount}
        defaultPage={1}
        page={pageNumber}
        size='large'
        onChange={(event, value) => handleSetPageNumber(value)}
      />
    );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '4rem' }}>
      {coursesLoading ? <Skeleton sx={{ width: '250px', height: '3rem' }} animation='wave' /> : pagination}
    </Box>
  );
}

export default FiltrationPagination;
