import { useMutation, useQueryClient } from 'react-query';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_COURSE_DETAILS, GET_MY_COURSES, GET_COURSES } from '../queries';

const nullFoo = () => {};
const nullObj = {
  onSuccess: nullFoo,
  onError: nullFoo,
};

export const useUpdateCourse = ({ onSuccess = nullFoo, onError = nullFoo } = nullObj) => {
  const queryClient = useQueryClient();
  let courseId = '';
  const { mutate, error, isLoading } = useMutation(
    (payload) => {
      courseId = payload.courseId;
      return CoursesController.updateCourse(payload.courseId, payload.course);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([GET_COURSE_DETAILS, courseId]);
        queryClient.refetchQueries([GET_COURSES]);
        queryClient.refetchQueries([GET_MY_COURSES]);

        onSuccess();
      },
      onError,
    }
  );

  return {
    updateCourse: (data) => mutate(data),
    error,
    isLoading,
  };
};
