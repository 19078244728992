import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import { BeeplomaTheme } from 'theme/BeeplomaTheme';
import { Box } from '@mui/system';

function withSkeleton(WrapperComponent) {
  return function ({ loading, style, sx, skeletonStyle, ...props }) {
    return loading ? (
      <Skeleton animation='wave' type='rounded' sx={{ ...sx, ...skeletonStyle }} style={style} />
    ) : (
      <WrapperComponent sx={sx} style={style} {...props} />
    );
  };
}

const SAvatar = withSkeleton(Avatar);
export function SkeletonAvatar({ image, loading, children, sx, skeletonStyle, ...props }) {
  const load = loading ?? !(image || children);
  return (
    <SAvatar
      loading={load}
      src={image}
      sx={{ width: '50px', height: '50px', ...sx }}
      skeletonStyle={{
        borderRadius: '100vmax',
        transform: 'none',
        boxSizing: 'border-box',
        bgcolor: BeeplomaTheme.palette.grey.main,
        border: `2px solid ${BeeplomaTheme.palette.grey.light}`,
        ...skeletonStyle,
      }}
      {...props}
    >
      {children}
    </SAvatar>
  );
}

export const SkeletonTypography = withSkeleton(Typography);

export default withSkeleton;
