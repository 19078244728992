import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';

const SectionTitle = styled(({ title, ...props }) => (
  <Typography {...props} component='p' variant='p1bold'>
    {title}
  </Typography>
))(({ theme }) => ({
  wordBreak: 'break-word',
  marginTop: '3px',
  color: theme.palette.grey.dark,
}));

const StyledLinkText = styled(({ text, ...props }) => (
  <Typography {...props} component='p' variant='p1bold'>
    {text}
  </Typography>
))(() => ({
  wordBreak: 'break-word',
  component: 'span',
  lineHeight: 'normal',
  variant: 'p1bold',
}));

function LinkContent({ loading, link, text }) {
  if (loading) {
    return <Skeleton width='100px' />;
  }
  if (link) {
    return (
      <Link href={link} target='_blank' underline='always'>
        <StyledLinkText text={text} />
      </Link>
    );
  }
  return <StyledLinkText text='No link yet' />;
}

function TextWithLink({ title, ...contentProps }) {
  return (
    <Box display='flex' gap='1em' sx={{ alignItems: 'center' }}>
      <SectionTitle title={title} />
      <LinkContent {...contentProps} />
    </Box>
  );
}

export default TextWithLink;
