import { useRef, useState, useMemo, useCallback } from 'react';
import { useAuth } from 'providers/auth';
import { courseStates } from 'constants/course';
import { useAddSelf, useDeleteSelf, useGetCourseDetails } from 'hooks/courses';
import { CourseListItem } from './CourseListItem';
import { UserCourseDialog } from './UserCourseDialog';
import { HandleParticipant } from '../HandleParticipant/HandleParticipant';

export function UserCourseListItem({ courseDetails }) {
  const newParticipantDialogRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { courseDetails: details, refetchDetails } = useGetCourseDetails(courseDetails?.id);

  const {
    state: { user },
  } = useAuth();

  const handleOpen = async (open) => {
    if (open === false) return setIsOpen(false);

    if (!details) await refetchDetails();
    return setIsOpen(true);
  };

  const isInstructor = useMemo(
    () => courseDetails?.instructor_id !== undefined && user.additionalInfo?.id === courseDetails?.instructor_id,
    [courseDetails, user]
  );
  const signed = useMemo(() => courseDetails?.is_mine && !isInstructor, [courseDetails, isInstructor]);

  const state = useMemo(() => {
    if (isInstructor) return courseStates.owned;
    if (signed) return courseStates.registered;
    return courseStates.unregistered;
  }, [signed, isInstructor]);

  const isDisabled = useMemo(
    () =>
      courseDetails?.no_available_places !== undefined &&
      (courseDetails?.no_available_places || 0) + (courseDetails?.no_available_places_online || 0) <= 0 &&
      signed === false,
    [courseDetails, signed]
  );

  const handleError = useCallback(() => {
    setIsLoading(false);
  }, []);
  const handleSuccess = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleAddSuccess = useCallback(() => {
    handleSuccess();
  }, [handleSuccess]);

  const handleDeleteSuccess = useCallback(() => {
    handleSuccess();
  }, [handleSuccess]);

  const { addSelf } = useAddSelf({
    onSuccess: handleAddSuccess.bind(this),
    onError: handleError.bind(this),
  });

  const { deleteSelf } = useDeleteSelf({
    onSuccess: handleDeleteSuccess,
    onError: handleError,
  });

  const handleAddSelf = useCallback(
    (placeId) => {
      setIsLoading(true);
      addSelf({ id: courseDetails?.id, placeId });
    },
    [user, courseDetails?.id, addSelf]
  );

  const handleDeleteSelf = useCallback(() => {
    setIsLoading(true);
    deleteSelf(courseDetails?.id);
  }, [user, courseDetails?.id, deleteSelf]);

  const handleAddParticipant = useCallback(() => {
    if (newParticipantDialogRef.current) {
      newParticipantDialogRef.current.open();
    }
  }, [newParticipantDialogRef.current]);

  const handleAddParticipantSuccess = useCallback(() => {}, []);

  const minusButtonAction = useCallback(() => {
    if (signed) handleDeleteSelf();
  }, [signed, handleDeleteSelf]);

  const plusButtonAction = useCallback(() => {
    if (signed) handleDeleteSelf();
    handleOpen();
  }, [user, signed, handleAddSelf, handleDeleteSelf]);

  const action = useMemo(() => {
    if (state === courseStates.owned) {
      return {
        takeAction: handleAddParticipant,
      };
    }

    if (state === courseStates.registered) {
      return {
        takeAction: minusButtonAction,
        isLoading,
        isDisabled: isDisabled || isLoading,
      };
    }

    return {
      takeAction: plusButtonAction,
      isLoading,
      isDisabled: isDisabled || isLoading,
    };
  }, [plusButtonAction, minusButtonAction, isLoading, isDisabled]);

  return (
    <>
      <CourseListItem courseDetails={courseDetails} registrationAction={action} state={state} />
      {isOpen && (
        <UserCourseDialog
          isOpen={isOpen}
          setIsOpen={handleOpen}
          courseDetails={details}
          handleAddSelf={handleAddSelf}
        />
      )}
      <HandleParticipant
        ref={newParticipantDialogRef}
        formId={`add_participant_${courseDetails?.id}`}
        courseId={courseDetails?.id}
        onSuccess={handleAddParticipantSuccess}
        courseDetails={courseDetails}
      />
    </>
  );
}
