import Typography from '../../Components/Typography/Typography';
import Box from '../../Components/Box/Box';

function Error() {
  return (
    <Box>
      <Typography ariant='h3' align='center'>
        Strona wyparowała :(
      </Typography>
    </Box>
  );
}

export default Error;
