import { useQuery } from 'react-query';
import { courseStatuses } from 'constants/course';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_COURSES } from '../queries';

export const useGetCourses = (status, pageNumber, itemsPerPage) => {
  const {
    data: courses,
    error,
    isLoading,
    refetch,
  } = useQuery([GET_COURSES, status, pageNumber, itemsPerPage], () =>
    CoursesController.getCourses(status, pageNumber, itemsPerPage)
  );

  return {
    courses,
    error,
    isLoading,
    refetch,
  };
};
