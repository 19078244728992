import { Box, styled } from '@mui/material';

export const FooterContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  background: theme.palette.secondary.main,
  padding: '3rem 5rem 4rem',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'start',
  [theme.breakpoints.up('xs')]: {
    gap: '3rem',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '5rem',
  },
  [theme.breakpoints.up('xl')]: {
    gap: '10rem',
  },
}));

export const listStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};

export const listItemStyle = {
  padding: '0',
  '&:hover': {
    bgcolor: 'secondary.main',
  },
};

export const cutCornerStyle = {
  bgcolor: 'white',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '13px 32px 13px 20px',
};
