import { useMutation, useQueryClient } from 'react-query';
import { UsersController } from '../../api/controller/users/UsersController';
import { GET_USERS_ME } from '../queries';

export const useUpdateMe = ({ onSuccess, onError }) => {
  const queryClient = useQueryClient();
  let currentUser = '';
  const { mutate, error, isLoading } = useMutation(
    (payload) => {
      currentUser = payload.currentUser;
      return UsersController.updateMe(payload.currentUser);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([GET_USERS_ME]);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError,
    }
  );

  return {
    updateMe: (data) => mutate(data),
    error,
    isLoading,
  };
};
