import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import FormContainer from 'Components/Form/FormContainer';
import { FormTextField } from 'Components/Form/Inputs';
import FormInput from 'Components/Form/Inputs/FormInput';
import { inputStyle } from 'Components/Form/Inputs/styles';
import ActionButton, { actionButtonIconStyle } from 'Components/Button/ActionButton';

export function HandleProfileForm({ formId, myDetails, handleCancel, onSubmit, isSaving, data }) {
  const signatureFileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [preview, setPreview] = useState();

  const onSelectFile = (e) => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    setSelectedFileName(e.target.files[0].name);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
  }, [selectedFile]);

  return (
    <Box sx={{ marginLeft: '12.5rem', marginTop: '2rem', maxWidth: '700px' }}>
      <Box>
        <FormContainer
          formProps={{ id: formId }}
          onSuccess={onSubmit}
          defaultValues={{
            description: myDetails.currentUser.description,
          }}
          loadValues={data}
        >
          <FormTextField
            name='description'
            validation={{
              maxLength: {
                value: 500,
                message: 'Description text is too long - max 500 characters',
              },
            }}
            multiline
            sx={inputStyle}
            variant='outlined'
            label='Description'
            size='small'
            minRows={4}
            maxRows={8}
            inputProps={{ maxLength: 501 }}
          />
          <FormInput
            name='signature_key'
            required={false}
            style={{ display: 'none' }}
            type='file'
            sx={{ width: '100%' }}
            accept='.png'
            ref={signatureFileInput}
            registerOptions={{
              onChange: onSelectFile,
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='regularbold' sx={{ marginLeft: '5px', lineHeight: '2rem', color: 'lightGrey.dark' }}>
              Upload signature:
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', paddingTop: '1rem' }}>
              <ActionButton variant='draft' text='select file' onClick={() => signatureFileInput.current.click()} />
              <Typography variant='p1' sx={{ color: 'lightGrey.dark', alignSelf: 'center' }}>
                (MAX 1MB, only .png files)
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column', gap: '12px' }}>
            {selectedFile && <img src={preview} style={{ maxWidth: '25%' }} alt='img' />}
            <Typography variant='string' fontSize='10px' color='#696969' wordbreak='break-word'>
              {selectedFileName}
            </Typography>
          </Box>
        </FormContainer>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: '2rem', marginTop: '3rem' }}>
        <ActionButton
          variant='cancel'
          disabled={isSaving}
          onClick={handleCancel}
          icon={<CancelIcon sx={actionButtonIconStyle} />}
          text='cancel'
        />
        <ActionButton
          variant='save'
          type='submit'
          form={formId}
          disabled={isSaving}
          loading={isSaving}
          icon={<SaveIcon sx={actionButtonIconStyle} />}
          text='save'
        />
      </Box>
    </Box>
  );
}
