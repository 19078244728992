import { Typography, Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import a4beeLogo from '../../assets/a4bee-logo.png';
import googleIcon from '../../assets/google-icon.png';
import { useAuth } from '../../providers/auth';
import {
  logInPageStyle,
  iconsBarStyle,
  pageContentStyle,
  mainHeaderStyle,
  fontSize19style,
  fontSize28style,
  fontSize39style,
  signInButtonStyle,
  signInBoxStyle,
} from './styles';
import withCutCorner from '../../Components/withCutCorner';

export function Login() {
  const {
    actions: { signIn },
  } = useAuth();

  const SignInBox = withCutCorner(Box);

  return (
    <Box sx={logInPageStyle}>
      <Box sx={iconsBarStyle}>
        <img src={a4beeLogo} alt='a4bee logo' width='50px' height='45px' />
      </Box>
      <Box sx={pageContentStyle}>
        <Typography sx={mainHeaderStyle}>
          Hi! <br />
          Log in to BeePloma Platform!
        </Typography>
        <SignInBox corner='TR' cornerSize='58px' sx={signInBoxStyle}>
          <Typography sx={fontSize19style}>Welcome!</Typography>
          <Typography sx={fontSize28style}>Sign in to</Typography>
          <Typography sx={fontSize39style}>Beeploma</Typography>
          <Button variant='text' sx={signInButtonStyle} onClick={() => signIn()}>
            <img src={googleIcon} alt='google logo' width='30px' height='30px' />
            <Typography sx={{ marginLeft: '15px' }}>Sign in with Google</Typography>
          </Button>
        </SignInBox>
      </Box>
    </Box>
  );
}
