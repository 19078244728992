import { useQuery } from 'react-query';
import { DiplomasController } from '../../api/controller/diplomas/DiplomasController';
import { GET_DIPLOMA_DETAILS } from '../queries';

export const useVerifyDiploma = (hash) => {
  const {
    data: diploma,
    error,
    isLoading,
  } = useQuery([GET_DIPLOMA_DETAILS, hash], () => DiplomasController.verify(hash));

  return {
    diploma,
    error,
    isLoading,
  };
};
