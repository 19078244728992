import { useQuery } from 'react-query';
import { CoursesController } from 'api/controller/courses/CoursesController';
import { GET_COURSES } from '../queries';

export const useGetCoursesFilters = (params) => {
  const {
    data: courses,
    error,
    isLoading,
    refetch,
  } = useQuery([GET_COURSES, params], () => CoursesController.getCoursesFilters(params));

  return {
    courses,
    error,
    isLoading,
    refetch,
  };
};
