import { createTheme } from '@mui/material';

const palette = {
  type: 'light',
  primary: {
    main: '#000000',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#FFDB2F',
    dark: '#F5CA00',
    contrastText: '#000000',
  },
  background: {
    default: '#EDEFF1',
    grey: '#f0f4f4',
  },
  text: {
    primary: '#000000',
    secondary: '#6e7982',
    disabled: '#a7afb4',
    hint: '#2dbecd',
  },
  error: {
    main: '#E12726',
  },
  warning: {
    main: '#E94E0E',
  },
  info: {
    main: '#004F9E',
  },
  success: {
    main: '#149B5F',
  },
  lightGrey: {
    light: '#F1F3F3',
    main: '#D5DDDD',
    dark: '#959A9A',
    contrastText: '#000000',
  },
  grey: {
    light: '#C7D0D0',
    main: '#B9C5C5',
    dark: '#8FA3A3',
    contrastText: '#000000',
  },
  darkGrey: {
    light: '#6A7676',
    main: '#455454',
    dark: '#2E3838',
    contrastText: '#FFFFFF',
  },
  lightBlue: {
    light: '#d6e7f2',
    main: '#CCE1EF',
    dark: '#8E9DA7',
  },
  blue: {
    light: '#6E9FD2',
    main: '#4A88C7',
    dark: '#335F8B',
    accent: '#4285F4',
    contrastText: '#013568',
  },
  darkBlue: {
    light: '#335D86',
    main: '#013568',
    dark: '#002548',
    contrastText: '#4A88C7',
  },
  raspberry: {
    main: '#CF1248',
  },
};

const sizeValues = {
  h1: '2.986rem',
  h2: '2.488rem',
  h3: '2.074rem',
  h4: '1.728rem',
  h5: '1.44rem',
  h6: '1.2rem',
  regular: '1rem',
  p1: '0.833rem',
  p2: '0.694rem',
  p3: '0.563rem',
};

const weightValues = {
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

const fontDefinitions = {};
Object.entries(sizeValues).forEach(([size, sizeValue]) => {
  fontDefinitions[size] = {
    fontSize: sizeValue,
  };
});

Object.entries(weightValues).forEach(([weight, weightValue]) => {
  Object.entries(sizeValues).forEach(([size, sizeValue]) => {
    fontDefinitions[size + weight] = {
      fontSize: sizeValue,
      fontWeight: weightValue,
    };
  });
});

export const BeeplomaTheme = createTheme({
  palette,
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    ...fontDefinitions,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1300,
      xxl: 2000,
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortes: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
});
