import { useMutation, useQueryClient } from 'react-query';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_COURSES, GET_MY_COURSES, GET_INSTRUCTOR_COURSES } from '../queries';
import { useAuth } from '../../providers/auth';

export const useFinishCourse = ({ onSuccess, onError }) => {
  const queryClient = useQueryClient();
  const {
    state: { user },
  } = useAuth();
  const { mutate, error, isLoading } = useMutation(
    (payload) => CoursesController.finishCourse(payload.courseId, payload.participants),
    {
      onSuccess: async () => {
        await Promise.all([queryClient.refetchQueries([GET_COURSES]), queryClient.refetchQueries([GET_MY_COURSES])]);
        if (user.additionalInfo.instructor_id) {
          queryClient.refetchQueries([GET_INSTRUCTOR_COURSES, user.additionalInfo.instructor_id]);
        }
        if (onSuccess) {
          onSuccess();
        }
      },
      onError,
    }
  );

  return {
    finishCourse: (data) => mutate(data),
    error,
    isLoading,
  };
};
