import { Card, CardHeader, CircularProgress, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useVerifyDiploma } from '../../../hooks/diplomas/useVerifyDiploma';
import { A4BeeBackground } from '../../../Components/A4BEE-Background/A4BeeBackground';
import { DiplomaDetails } from '../diploma-details/DiplomaDetails';

export function DiplomaVerify() {
  const { hash } = useParams();
  const { diploma, isLoading } = useVerifyDiploma(hash);
  return (
    <Box
      boxSizing='border-box'
      position='relative'
      p={2}
      minHeight='100vh'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      {isLoading && (
        <Box zIndex='1' display='flex' alignItems='center' justifyContent='center' flexDirection='column' gap={2}>
          <CircularProgress />
          <Typography variant='caption'>Verifying...</Typography>
        </Box>
      )}
      {!isLoading && (
        <Box zIndex='1' width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
          {!diploma?.valid && (
            <Card sx={{ width: '100%', maxWidth: '400px' }}>
              <CardHeader
                title={
                  <Box display='flex' alignItems='center' justifyContent='center' gap={2}>
                    <Typography textAlign='center' lineHeight='normal' variant='body1'>
                      Provided Diploma is not valid
                    </Typography>
                    <SentimentVeryDissatisfiedIcon />
                  </Box>
                }
              />
            </Card>
          )}
          {diploma?.valid && (
            <Container>
              <Box mt='150px'>
                <DiplomaDetails diploma={diploma.diploma} />
              </Box>
            </Container>
          )}
        </Box>
      )}
      <A4BeeBackground />
    </Box>
  );
}
