import { useMutation, useQueryClient } from 'react-query';
import { courseStatuses } from 'constants/course';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_COURSES, GET_COURSE_DETAILS, GET_MY_COURSES } from '../queries';

const nullFoo = () => {};
const nullObj = {
  onSuccess: nullFoo,
  onError: nullFoo,
};

export const useDeleteSelf = ({ onSuccess = nullFoo, onError = nullFoo } = nullObj) => {
  const queryClient = useQueryClient();
  let courseId = '';

  const { mutate, error, isLoading } = useMutation(
    (id) => {
      courseId = id;
      return CoursesController.deleteSelf(courseId);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.refetchQueries([GET_COURSE_DETAILS, courseId]),
          queryClient.refetchQueries([GET_MY_COURSES]),
          queryClient.refetchQueries([GET_COURSES]),
        ]);
        onSuccess();
      },
      onError,
    }
  );

  return {
    deleteSelf: mutate,
    error,
    isLoading,
  };
};
