import { TextField, styled } from '@mui/material';

export const filtrationListStyle = {
  boxSizing: 'border-box',
  marginTop: '4rem',
  marginBottom: '4rem',
  marginInline: {
    xs: '1rem',
    sm: '2rem',
    lg: '3rem',
    xl: '5rem',
  },
};

export const dateTimeStyle = {
  width: '150px',
  color: 'black',
};

export const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root.Mui-error': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#a7afb4',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#a7afb4 !important',
  },
});
