import { Avatar, Tooltip } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useRef, useMemo, useCallback } from 'react';
import { stringToColor } from 'utils/stringToColor';
import { useUserData } from 'providers/user';
import Typography from '@mui/material/Typography';
import { SkeletonAvatar } from 'Components/withSkeleton';
import { Box } from '@mui/system';

const avatarDiameter = 40;
const containerStyle = {
  position: 'relative',
  width: '100%',
  height: `${avatarDiameter}px`,
  minHeight: `${avatarDiameter}px`,
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  paddingRight: `${avatarDiameter / 2}px`,
  boxSizing: 'border-box',
  gap: '5px',
};

const avatarStyle = {
  width: `${avatarDiameter}px`,
  height: `${avatarDiameter}px`,
  position: 'absolute',
  textTransform: 'uppercase',
  left: 0,
};

const MoreAvatar = styled(Avatar)(({ theme }) => ({
  ...avatarStyle,
  fontWeight: '700',
  background: theme.palette.grey.main,
  color: theme.palette.grey.dark,
}));

function stringAvatar(first_name, last_name) {
  return {
    sx: {
      ...avatarStyle,
      bgcolor: stringToColor(`${first_name} ${last_name}`),
    },
    children: `${first_name[0]}${last_name[0]}`,
  };
}

function ParticipantList({ participants, maxAvatars = 20 }) {
  const container = useRef(null);
  const showMoreAvatar = useMemo(() => participants?.length > maxAvatars, [participants]);

  const {
    state: { users },
  } = useUserData();

  const renderParticipants = useCallback(
    (maxAv) => {
      if (participants?.length === 0 || participants === undefined) {
        return <Typography variant='p1bold'>No participants</Typography>;
      }
      return (
        <>
          {participants?.map((singleParticipant, index) => {
            if (showMoreAvatar && index >= maxAv - 1) return null;
            const participant = users.find((user) => user.id === singleParticipant.participant_id);
            return (
              <Tooltip key={`${participant.id}_tooltip`} title={`${participant.first_name} ${participant.last_name}`}>
                <Box key={`${participant.id}_avatar`} position='relative' maxWidth='40px' width='40px'>
                  <Avatar {...stringAvatar(participant?.first_name, participant?.last_name)} />
                </Box>
              </Tooltip>
            );
          })}
          {showMoreAvatar && (
            <Box position='relative' maxWidth='40px' width='40px'>
              <MoreAvatar id='more-people'>{`+${participants.length - (maxAv - 1)}`}</MoreAvatar>
            </Box>
          )}
        </>
      );
    },
    [participants, showMoreAvatar]
  );

  return (
    <div ref={container} style={containerStyle}>
      {renderParticipants(maxAvatars)}
    </div>
  );
}

export default React.memo(ParticipantList);
