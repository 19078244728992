import { useQuery } from 'react-query';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_MY_COURSES } from '../queries';

export const useGetMyCourses = () => {
  const {
    data: myCourses,
    error,
    isLoading,
    refetch,
  } = useQuery([GET_MY_COURSES], () => CoursesController.getMyCourses());

  return {
    myCourses,
    error,
    isLoading,
    refetch,
  };
};
