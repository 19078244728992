import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

export const actionButtonIconStyle = {
  paddingRight: {
    xs: '2px',
    md: '4px',
    lg: '6px',
    xl: '8px',
  },
  fontSize: {
    xs: '1.75rem',
    sm: '1.75rem',
    md: '1.5rem',
  },
};

function StyledActionButton({ children, variant, ...props }) {
  const textColor = variant === 'save' ? 'primary.main' : 'primary.contrastText';
  let buttonBackgroundColor;
  switch (variant) {
    case 'save':
      buttonBackgroundColor = 'secondary.main';
      break;
    case 'cancel':
      buttonBackgroundColor = 'error.main';
      break;
    case 'draft':
      buttonBackgroundColor = 'grey.main';
      break;
    default:
      buttonBackgroundColor = 'secondary.main';
  }

  return (
    <LoadingButton
      onClick={props.onClick}
      type={props.type}
      form={props.form}
      disabled={props.disabled}
      loading={props.loading}
      sx={{
        padding: {
          xs: '4px 8px',
          md: '6px 12px',
          lg: '8px 16px',
          xl: '10px 20px',
        },
        borderRadius: '10px',
        '&:hover': {
          bgcolor: 'success.main',
          color: 'primary.contrastText',
          transition: '0.25s',
        },
        color: textColor,
        backgroundColor: buttonBackgroundColor,
      }}
    >
      {children}
    </LoadingButton>
  );
}

export default function ActionButton({ icon, text, variant, disabled, onClick, type, form, loading }) {
  return (
    <StyledActionButton
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      type={type}
      form={form}
      loading={loading}
    >
      {icon}
      <Typography sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{text}</Typography>
    </StyledActionButton>
  );
}
