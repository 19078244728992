import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useGetMe } from 'hooks/users';
import { useAuth } from 'providers/auth';
import { HandleProfile } from 'Components/Profile/HandleProfile';
import { GetInitials } from 'utils/GetInitials';
import { initialsAvatarStyle, myProfileContainerStyle, userDetailsStyle } from './style';

const onSuccess = () => {};
const onError = () => {};

export function MyProfile() {
  const myDetails = useGetMe({ onSuccess, onError });

  const {
    state: { user },
  } = useAuth();

  return (
    <Box sx={myProfileContainerStyle} minHeight='calc(100vh - 90px)'>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Typography sx={{ fontSize: '2rem', fontWeight: '700', marginBottom: '3rem', color: 'lightGrey.main' }}>
          👨‍🎓 Dashboard /
        </Typography>
        <Typography sx={{ fontSize: '2rem', fontWeight: '700', marginBottom: '3rem' }}>My profile</Typography>
      </Box>
      <Box marginLeft='4rem' paddingTop='2rem'>
        <Box sx={userDetailsStyle}>
          <Typography sx={initialsAvatarStyle}>
            {GetInitials(`${myDetails.currentUser.first_name} ${myDetails.currentUser.last_name}`)}
          </Typography>
          <Box>
            <Typography variant='h3bold'>
              {myDetails.currentUser.first_name} {myDetails.currentUser.last_name}
            </Typography>
            {user.roles.includes('admin') && (
              <Typography variant='h6bold' sx={{ color: 'raspberry.main', display: 'block' }}>
                admin
              </Typography>
            )}
          </Box>
        </Box>
        <HandleProfile myDetails={myDetails} />
      </Box>
    </Box>
  );
}
