import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { CoursesController } from '../../api/controller/courses/CoursesController';
import { GET_COURSES } from '../queries';

export const useCreateCourse = ({ onSuccess, setOpenDialog, onError }) => {
  const queryClient = useQueryClient();
  let cardId = '';
  let pastDate = '';
  const { mutate, error, isLoading } = useMutation(
    (payload) => {
      cardId = payload.cardId;
      pastDate = payload.data?.meeting_start_time < dayjs() && payload.data?.meeting_end_time < dayjs();
      return CoursesController.createCourse({ ...payload.data });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([GET_COURSES]);
        if (onSuccess) {
          onSuccess(cardId);
          pastDate ? setOpenDialog(true) : setOpenDialog(false);
        }
      },
      onError,
    }
  );

  return {
    createCourse: (payload) => mutate(payload),
    error,
    isLoading,
  };
};
