import { Client } from '../../client/Client';
import { USERS_ENDPOINT } from '../../config/Client';

export class UsersController {
  static getMe() {
    return Client.get(`${USERS_ENDPOINT}/me`);
  }

  static updateMe(currentUser) {
    const config = { headers: { 'Content-type': 'multipart/form-data' } };
    return Client.put(`${USERS_ENDPOINT}/me`, currentUser, config);
  }

  static getAll() {
    return Client.get(`${USERS_ENDPOINT}`);
  }

  static getAllWithSignature() {
    return Client.get(`${USERS_ENDPOINT}?has_signature=true`);
  }

  static getAllIsInstructor(pageNumber, itemsPerPage) {
    if (pageNumber && itemsPerPage) {
      return Client.get(`${USERS_ENDPOINT}?is_instructor=true&page=${pageNumber}&size=${itemsPerPage}`);
    }
    return Client.get(`${USERS_ENDPOINT}?is_instructor=true`);
  }
}
