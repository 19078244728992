import { useLocation, useNavigate } from 'react-router-dom';
import { List } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SchoolIcon from '@mui/icons-material/School';
import HelpIcon from '@mui/icons-material/Help';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../../providers/auth';
import { iconStyle, listStyle } from '../style';
import MenuListItem from './MenuListItem';
import { withAdminRole } from '../../WithRole/WithRole';

const RestrictedAdminMode = withAdminRole(MenuListItem);
const RestrictedUserMode = withAdminRole(MenuListItem);

export default function MenuList({ handleClose }) {
  const {
    actions: { signOut },
  } = useAuth();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <List sx={listStyle}>
      {!/^\/admin/i.test(pathname) && (
        <RestrictedAdminMode
          text='Admin mode'
          color='raspberry.main'
          onClick={() => {
            navigate('/admin');
            handleClose();
          }}
        >
          <PersonIcon sx={[iconStyle, { color: 'raspberry.main' }]} />
        </RestrictedAdminMode>
      )}
      {/^\/admin/i.test(pathname) && (
        <RestrictedUserMode
          text='Back to user mode'
          color='info.main'
          onClick={() => {
            navigate('/');
            handleClose();
          }}
        >
          <PersonIcon sx={[iconStyle, { color: 'info.main' }]} />
        </RestrictedUserMode>
      )}
      <MenuListItem
        text='Home'
        onClick={() => {
          navigate(/^\/admin/i.test(pathname) ? '/admin' : '/');
          handleClose();
        }}
      >
        <HomeIcon sx={iconStyle} />
      </MenuListItem>
      <MenuListItem
        text='My profile'
        onClick={() => {
          navigate('/myprofile');
          handleClose();
        }}
      >
        <PersonIcon sx={iconStyle} />
      </MenuListItem>
      <MenuListItem
        text='Find your course'
        onClick={() => {
          navigate(/^\/admin/i.test(pathname) ? '/admin/courses-filtration' : '/courses-filtration');
          handleClose();
        }}
      >
        <ManageSearchIcon sx={iconStyle} />
      </MenuListItem>
      <MenuListItem
        text='Instructors'
        onClick={() => {
          navigate('/list-of-instructors');
          handleClose();
        }}
      >
        <LocalLibraryIcon sx={iconStyle} />
      </MenuListItem>
      <MenuListItem
        text='How to use certificate'
        onClick={() => {
          navigate('/how-to-use-certificate');
          handleClose();
        }}
      >
        <SchoolIcon sx={iconStyle} />
      </MenuListItem>
      <MenuListItem
        text='Instructor`s To Do'
        onClick={() => {
          navigate('/todo-for-instructor');
          handleClose();
        }}
      >
        <HelpIcon sx={iconStyle} />
      </MenuListItem>
      <MenuListItem text='Log out' onClick={() => signOut()}>
        <LogoutIcon sx={iconStyle} />
      </MenuListItem>
    </List>
  );
}
