import { Box, Dialog, DialogActions, DialogContent, Drawer, Typography } from '@mui/material';
import { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { BeeplomaTheme } from 'theme/BeeplomaTheme';
import ActionButton, { actionButtonIconStyle } from 'Components/Button/ActionButton';

const directions = ['left', 'right', 'top', 'bottom'];

const dialogTypes = {
  dialog: (props) => <Dialog {...props} />,
  ...Object.fromEntries(
    directions.flatMap((direction) => [
      [
        `${direction}Drawer`,
        ({ children, ...props }) => (
          <Drawer anchor={direction} {...props}>
            {children}
          </Drawer>
        ),
      ],
    ])
  ),
};

const FormDialog = forwardRef(
  (
    {
      renderForm,
      renderTitle,
      renderSuccessButtons,
      isSaving,
      disableSave,
      onCancel = () => {},
      onOpen = () => {},
      onClose = () => {},
      width = '40%',
      type = 'dialog',
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
      setOpen(true);
      onOpen();
    }, []);

    const handleClose = useCallback(() => {
      setOpen(false);
      onClose();
    }, []);

    const VariableDialog = useCallback(
      (props) => {
        if (type === undefined) return <Dialog {...props} />;
        return dialogTypes[type](props);
      },
      [type]
    );
    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));

    return (
      <VariableDialog
        open={open}
        sx={{
          '.MuiDialog-paper, .MuiDrawer-paper': {
            width,
            maxWidth: '100wv',
            background: BeeplomaTheme.palette.lightGrey.light,
          },
        }}
        onClose={handleClose}
      >
        <DialogContent>
          <Box pt='1'>
            {renderTitle()}
            {renderForm()}
          </Box>
        </DialogContent>
        <DialogActions>
          <ActionButton
            variant='cancel'
            disabled={isSaving}
            onClick={handleClose}
            icon={<CancelIcon sx={actionButtonIconStyle} />}
            text='cancel'
          />
          {renderSuccessButtons && renderSuccessButtons()}
        </DialogActions>{' '}
      </VariableDialog>
    );
  }
);

export default FormDialog;
