import Drawer from '@mui/material/Drawer';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DoneIcon from '@mui/icons-material/DoneOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import ComputerIcon from '@mui/icons-material/Computer';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import dayjs from 'dayjs';
import React, { useCallback, useState, forwardRef, useRef, useImperativeHandle, useMemo, useEffect } from 'react';
import { HandleCourse } from 'Components/Courses/HandleCourse/HandleCourse';
import { useUpdateCourse, useDeleteCourse, useGetCourseDetails } from 'hooks/courses';
import { courseStates, courseStatuses } from 'constants/course';
import { useUserData } from 'providers/user';
import { dateTimeFormat } from 'constants/global';
import withSkeleton, { SkeletonAvatar } from 'Components/withSkeleton';
import { GetInitials } from 'utils/GetInitials.js';
import { BeeplomaTheme } from 'theme/BeeplomaTheme';
import * as jsonpatch from 'fast-json-patch/index';
import { Chip } from '@mui/material';
import TextWithIcon from './TextWithIcon';
import TextWithLink from './TextWithLink';
import ParticipantList from './ParticipantsList';
import { tagDrawerStyle } from './styles';

const loadingButtonStyles = {
  minWidth: '40px',
  width: '40px',
  maxWidth: '40px',
  minHeight: '40px',
  height: '40px',
  maxHeight: '40px',
  borderRadius: '50%',
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...theme.mixins.toolbar,
  marginBottom: '20px',
  '& > button:first-of-type': {
    marginRight: 'auto',
  },
}));

function IconBasedOnState({ state }) {
  if (
    state === courseStates.unregistered ||
    state === courseStates.admin ||
    state === courseStates.isNotFinished ||
    state === courseStates.owned
  )
    return <AddIcon />;
  return <RemoveIcon />;
}

const ActionButton = styled(({ state, children, ...props }) => (
  <LoadingButton {...props} startIcon={IconBasedOnState({ state })}>
    {children}
  </LoadingButton>
))(({ theme, state }) => ({
  background: state === courseStates.registered ? theme.palette.lightGrey.main : theme.palette.secondary.main,
  padding: '7px 20px',
  marginLeft: 'auto',
  borderRadius: '10px',
  width: 'max-content',
  maxWidth: 'auto',
  maxHeight: '40px',
  minWidth: '40px',
  minHeight: '40px',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    background: state === courseStates.registered ? theme.palette.lightGrey.dark : theme.palette.secondary.dark,
  },
  ' .MuiButton-startIcon': {
    fontSize: '32px',
  },
}));

const SkeletonTypography = withSkeleton(Typography);
const SkeletonDrawerHeader = withSkeleton(DrawerHeader);

const SectionTitle = styled(({ title, ...props }) => (
  <Typography {...props} component='p' variant='p1bold'>
    {title}
  </Typography>
))(({ theme }) => ({
  wordBreak: 'break-word',
  marginTop: '2em',
  color: theme.palette.grey.dark,
}));

export const CourseDetailsDrawer = React.memo(
  forwardRef(({ courseId, courseState, instructorName, onRegisterClick, onFinishClick, isActionLoading }, ref) => {
    const [wasOpened, setWasOpened] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [coursePlaces, setCoursePlaces] = useState(null);
    const { courseDetails, isLoading: detailsLoading, refetchDetails } = useGetCourseDetails(courseId);
    const { palette } = useTheme();
    const editDialogRef = useRef(null);

    const {
      state: { places },
    } = useUserData();

    const startDate = useMemo(
      () =>
        courseDetails?.meeting_start_time === undefined
          ? [undefined, undefined]
          : dayjs(courseDetails?.meeting_start_time).format(dateTimeFormat).split(' '),
      [courseDetails?.meeting_start_time]
    );
    const endDate = useMemo(
      () =>
        courseDetails?.meeting_end_time === undefined
          ? [undefined, undefined]
          : dayjs(courseDetails?.meeting_end_time).format(dateTimeFormat).split(' '),
      [courseDetails?.meeting_end_time]
    );

    const displayParticipantsNumber =
      courseDetails?.no_places === null || courseDetails?.no_places === 0 ? 'none' : 'flex';

    const displayParticipantsNumberOnline =
      courseDetails?.no_places_online === null || courseDetails?.no_places_online === 0 ? 'none' : 'flex';

    const isAdminOrInstructor =
      courseState === courseStates.admin ||
      courseState === courseStates.owned ||
      courseState === courseStates.isNotFinished;

    const isUserAndPastDate =
      courseState !== courseStates.admin &&
      courseState !== courseStates.owned &&
      !courseDetails?.is_finished &&
      dayjs(courseDetails?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') <
        dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS');

    const isPastButNotFinish =
      courseDetails?.status === courseStatuses.published && courseState === courseStates.isNotFinished;

    const showParticipationButton = useMemo(
      () =>
        courseDetails &&
        courseDetails.status === courseStatuses.published &&
        !isUserAndPastDate &&
        (!courseDetails.is_finished || isAdminOrInstructor),
      [courseDetails, isUserAndPastDate, courseState]
    );

    const participationButtonText = useMemo(() => {
      switch (courseState) {
        case courseStates.owned:
        case courseStates.admin:
          return 'Add participant';
        case courseStates.isNotFinished:
          return 'Add participant';
        case courseStates.registered:
          return 'Unregister';
        case courseStates.unregistered:
          return 'Register';
        default:
          return '';
      }
    }, [courseState]);

    useEffect(() => {
      if (places) {
        if (courseDetails?.place_ids?.length > 0) {
          const result = [];
          courseDetails?.place_ids.forEach((place_id) => {
            const place = places?.find((place) => place?.id === place_id);
            result.push(place);
          });
          setCoursePlaces(result);
        } else if (courseDetails && !courseDetails.place_ids.length > 0) {
          setCoursePlaces([]);
        }
      }
    }, [courseDetails, places]);

    const handleClose = useCallback(() => {
      setExpanded(false);
    }, []);

    const handleOpen = useCallback(() => {
      if (!wasOpened) {
        if (!courseDetails && !detailsLoading) {
          refetchDetails();
        }
      }
      setWasOpened(true);
      setExpanded(true);
    }, [wasOpened, refetchDetails]);

    const handleEditClick = useCallback(() => {
      if (editDialogRef.current) {
        editDialogRef.current.open();
      }
    }, [editDialogRef.current]);

    const handleCancel = useCallback(() => {
      if (editDialogRef.current) {
        editDialogRef.current.close();
      }
    }, [editDialogRef.current]);

    const { updateCourse, isLoading: isSaving } = useUpdateCourse({ onSuccess: handleCancel });
    const { deleteCourse, isLoading: isDeleteLoading } = useDeleteCourse({ onSuccess: handleClose });

    const handleSave = useCallback(
      (data) => {
        data.meeting_start_time = dayjs(data.meeting_start_time).toISOString();
        data.meeting_end_time = dayjs(data.meeting_end_time).toISOString();

        updateCourse({ courseId: courseDetails?.id, course: jsonpatch.compare(courseDetails, data) });
      },
      [courseDetails?.id, updateCourse]
    );

    const handleDelete = useCallback(() => {
      deleteCourse(courseDetails?.id);
    }, [deleteCourse, courseDetails?.id]);

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }));
    return (
      <>
        <Drawer
          sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 'min(100vw, 492px)',
              padding: '20px',
              background: palette.lightGrey.light,
            },
          }}
          variant='temporary'
          anchor='right'
          transitionDuration={200}
          hideBackdrop={false}
          open={expanded}
          onClose={handleClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <SkeletonDrawerHeader loading={!courseDetails}>
            <IconButton color='primary' variant='contained' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <div>
              {isAdminOrInstructor &&
                courseDetails?.status === courseStatuses.published &&
                !courseDetails?.is_finished && (
                  <IconButton sx={loadingButtonStyles} color='primary' onClick={onFinishClick}>
                    <DoneIcon />
                  </IconButton>
                )}

              {isAdminOrInstructor && (
                <IconButton sx={loadingButtonStyles} color='primary' onClick={handleEditClick}>
                  <EditIcon />
                </IconButton>
              )}

              {isAdminOrInstructor && !courseDetails?.is_finished && (
                <IconButton color='primary' sx={loadingButtonStyles} onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </SkeletonDrawerHeader>
          <SkeletonTypography
            loading={courseDetails?.name === undefined}
            skeletonStyle={{ height: '60px', width: '100px' }}
            style={{ wordBreak: 'break-word' }}
            component='span'
            lineHeight='normal'
            variant='h2bold'
          >
            {courseDetails?.name}
          </SkeletonTypography>
          <div style={{ display: 'flex', gap: '1em', alignItems: 'center', marginTop: '5px' }}>
            <SkeletonAvatar
              sx={{ bgcolor: BeeplomaTheme.palette.blue.main }}
              loading={courseDetails?.instructor_id === undefined}
            >
              {GetInitials(instructorName)}
            </SkeletonAvatar>
            <SkeletonTypography
              loading={courseDetails?.instructor_id === undefined}
              skeletonStyle={{ height: '20px', width: '100px' }}
              style={{ wordBreak: 'break-word' }}
              component='span'
              lineHeight='normal'
              variant='h5bold'
            >
              {instructorName}
            </SkeletonTypography>
          </div>
          <TextWithIcon text={coursePlaces?.map((place) => place.name).join(', ')} icon={PlaceIcon} marginTop='2.5em' />
          {[startDate, endDate].map(([date, time], index) => (
            <div
              key={index === 0 ? 'startDate' : 'endDate'}
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '20px' }}
            >
              <TextWithIcon text={date} icon={CalendarMonthIcon} />
              <TextWithIcon text={time} icon={ScheduleIcon} />
            </div>
          ))}
          <Box>
            <SectionTitle title='Type' mb='10px' />
            <SkeletonTypography
              loading={!courseDetails?.type}
              skeletonStyle={{ height: '100px' }}
              style={{ wordBreak: 'break-word', fontWeight: '600' }}
              component='p'
              variant='p1bold'
            >
              {courseDetails?.type || ''}
            </SkeletonTypography>
          </Box>
          {courseDetails?.status === courseStatuses.published && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end',
                  cursor: 'default',
                  gap: '0.5em',
                  color: BeeplomaTheme.palette.grey.dark,
                }}
              >
                <SectionTitle title='Participants' mt='0' />
                {courseDetails?.is_finished === false && (
                  <>
                    <Box
                      display={displayParticipantsNumber}
                      sx={{
                        alignItems: 'center',
                        marginRight: '0.4em',
                      }}
                    >
                      <PeopleIcon style={{ marginRight: '0.2em' }} />
                      <Typography variant='h6bold' fontSize='1rem'>{`${
                        courseDetails.no_places - courseDetails.no_available_places
                      }`}</Typography>
                      <Typography
                        variant='h6bold'
                        fontSize='1rem'
                        style={{ color: BeeplomaTheme.palette.grey.main }}
                      >{`/${courseDetails.no_places}`}</Typography>
                    </Box>
                    <Box display={displayParticipantsNumberOnline} sx={{ alignItems: 'center' }}>
                      <ComputerIcon style={{ marginRight: '0.3em' }} />
                      <Typography variant='h6bold' gap='0' fontSize='1rem'>{`${
                        courseDetails.no_places_online - courseDetails.no_available_places_online
                      }`}</Typography>
                      <Typography
                        variant='h6bold'
                        fontSize='1rem'
                        style={{ color: BeeplomaTheme.palette.grey.main }}
                      >{`/${courseDetails?.no_places_online || 0}`}</Typography>
                    </Box>
                  </>
                )}
              </Box>
              <ParticipantList participants={courseDetails?.participants} />
            </>
          )}
          <SectionTitle title='Description' mb='10px' />
          <SkeletonTypography
            loading={!courseDetails?.description}
            skeletonStyle={{ height: '100px' }}
            style={{ wordBreak: 'break-word', fontWeight: '600' }}
            component='p'
            variant='p1bold'
          >
            {courseDetails?.description || ''}
          </SkeletonTypography>
          <Box mt='10px' pb='2em'>
            <TextWithLink
              loading={detailsLoading}
              text={`${courseDetails?.name}_presentation`}
              link={courseDetails?.presentation_link}
              title='Presentation link:'
            />
            <TextWithLink
              loading={detailsLoading}
              text={`${courseDetails?.name}_recording`}
              link={courseDetails?.recording_link}
              title='Recording link:'
            />
          </Box>
          {courseDetails?.tags.length > 0 ? (
            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '2rem', alignItems: 'center' }}>
              <Typography variant='p1bold' sx={{ color: 'grey.dark' }}>
                Tags:
              </Typography>
              {courseDetails?.tags.map((tag) => (
                <Chip key={`${courseDetails.id}${tag}`} variant='outlined' label={`#${tag}`} sx={tagDrawerStyle} />
              ))}
            </Box>
          ) : null}
          {showParticipationButton && (
            <ActionButton
              state={courseState}
              onClick={onRegisterClick}
              loading={isActionLoading}
              disabled={
                (courseDetails?.no_available_places || 0) + (courseDetails?.no_available_places_online || 0) <= 0 &&
                courseState !== courseStates.registered &&
                courseState !== courseStates.admin &&
                courseState !== courseStates.owned
              }
            >
              {participationButtonText}
            </ActionButton>
          )}
          {isPastButNotFinish && (
            <ActionButton state={courseState} onClick={onRegisterClick} loading={isActionLoading}>
              {participationButtonText}
            </ActionButton>
          )}
        </Drawer>
        <HandleCourse
          ref={editDialogRef}
          formId={`edit_course_${courseDetails?.id}`}
          onSubmit={handleSave}
          isSaving={isSaving}
          data={courseDetails}
        />
      </>
    );
  })
);
