export function findPlaceNameById(places, id) {
  const obj = places.find((obj) => obj.id === id);
  if (obj) {
    return obj.name;
  }
  return null;
}

export function findPlaceIdByName(places, name) {
  const obj = places.find((obj) => obj.name === name);
  if (obj) {
    return obj.id;
  }
  return null;
}
