import { Typography } from '@mui/material';
import { GetInitials } from 'utils/GetInitials';
import { InitialsAvatar, InstructorDetailsBox, InstructorItem } from './style';

export function InstructorsListItem({ user, isLoading }) {
  return (
    <InstructorItem>
      <InitialsAvatar variant='h3bold'>{GetInitials(`${user.first_name} ${user.last_name}`)}</InitialsAvatar>
      <InstructorDetailsBox>
        <Typography variant='h6bold'>
          {user.first_name} {user.last_name}
        </Typography>
        <Typography variant='p1bold' noWrap sx={{ color: 'grey.dark', marginTop: '5px' }}>
          {user.email}
        </Typography>
      </InstructorDetailsBox>
    </InstructorItem>
  );
}
