import { DateTimeChecker } from 'utils/DateTimeChecker';
import dayjs from 'dayjs';

const requiredFields = {
  name: 'Course name is required',
  instructor_id: 'Instructor ID is required',
  description: 'Description is required.',
  place_ids: 'Place is required',
  no_places: 'Please set number of available places',
  no_places_online: 'Please set number of available places',
  meeting_start_time: 'Start time required',
  meeting_end_time: 'End time required',
  hours_to_prepare: 'Please set number of hours to prepare',
  type: 'Type is required',
};

const maxLengthFields = {
  name: {
    value: 128,
    message: 'Course name cannot be longer than 128 characters',
  },
  description: {
    value: 1000,
    message: 'Description text is too long - max 1000 characters',
  },
  no_places: {
    value: 250,
    message: 'Number of participants cannot be more than 250',
  },
  no_places_online: {
    value: 250,
    message: 'Number of participants cannot be more than 250',
  },
  hours_to_prepare: {
    value: 168,
    message: 'Number of hours to prepare must be between 0 and 168',
  },
};

const minLengthFields = {
  name: {
    value: 1,
    message: 'Course name must be between 1 and 128 characters',
  },
  description: {
    value: 1,
    message: 'Description must be between 1 and 1000 characters',
  },
  no_places: {
    value: 0,
    message: 'Number of participants cannot be less than 0',
  },
  no_places_online: {
    value: 0,
    message: 'Number of participants cannot be less than 0',
  },
  hours_to_prepare: {
    value: 0,
    message: 'Number of hours to prepare must be at least 1',
  },
};

const validationParameters = [
  {
    fields: maxLengthFields,
    isInvalid: (value, params) => (Number.isNaN(value) ? value?.length > params.value : value > params.value),
  },
  {
    fields: minLengthFields,
    isInvalid: (value, params) => (Number.isNaN(value) ? value?.length < params.value : value < params.value),
  },
];

export const useHandleCourseResolver = (places) => {
  const resolver = (values, _, { fields }) => {
    const errors = {};
    const start_date_valid = dayjs(values.meeting_start_time).isValid();
    const end_date_valid = dayjs(values.meeting_end_time).isValid();
    const idExternalCourse = places?.find((place) => place.type === 'external')?.id;
    const idOnlineCourse = places?.find((place) => place.type === 'online')?.id;
    const OnsiteCourseIds = places?.filter((place) => place.type === 'onsite').map((place) => place.id);

    if (values.place_ids.includes(idExternalCourse) && values.place_ids.length === 1) {
      errors.place_ids = {
        message: `The course cannot take place only in 'external'`,
      };
    }

    if (
      OnsiteCourseIds.some((place) => values.place_ids.includes(place)) &&
      !values.place_ids.includes(idOnlineCourse) &&
      (values.no_places < 1 || values.no_places_online > 0)
    ) {
      if (values.no_places < 1) {
        errors.no_places = {
          message: `You need to set number of places onsite`,
        };
      }
      if (values.no_places_online > 0) {
        errors.no_places_online = {
          message: `You cannot set places online`,
        };
      }
    }

    if (
      values.place_ids.includes(idOnlineCourse) &&
      !OnsiteCourseIds.some((place) => values.place_ids.includes(place)) &&
      (values.no_places_online < 1 || values.no_places > 0)
    ) {
      if (values.no_places_online < 1) {
        errors.no_places_online = {
          message: `You need to set number of places online`,
        };
      }
      if (values.no_places > 0) {
        errors.no_places = {
          message: `You cannot set places oniste`,
        };
      }
    }

    if (
      OnsiteCourseIds.some((place) => values.place_ids.includes(place)) &&
      values.place_ids.includes(idOnlineCourse) &&
      (values.no_places_online < 1 || values.no_places < 1)
    ) {
      if (values.no_places_online < 1) {
        errors.no_places_online = {
          message: `You need to set number of places online`,
        };
      }

      if (values.no_places < 1) {
        errors.no_places = {
          message: `You need to set number of places onsite`,
        };
      }
    }

    if (!values.participants.every((participant) => values.place_ids.includes(participant.place_id))) {
      errors.place_ids = {
        message: `If you want remove course location - check participants assigned to this place`,
      };
    }

    if (start_date_valid && end_date_valid) {
      const start_date_equal_default_and_not_empty =
        fields?.meeting_start_time?.ref.value &&
        dayjs(values.meeting_start_time).isSame(dayjs(fields?.meeting_start_time?.ref.value), 'minutes');
      const end_date_equal_default_and_not_empty =
        fields?.meeting_end_time?.ref.value &&
        dayjs(values.meeting_end_time).isSame(dayjs(fields?.meeting_end_time?.ref.value), 'minutes');

      if (!start_date_equal_default_and_not_empty || !end_date_equal_default_and_not_empty) {
        if (!DateTimeChecker(values.meeting_start_time, values.meeting_end_time)) {
          errors.meeting_start_time = {
            message: 'Start date cannot be later than the end date',
          };

          errors.meeting_end_time = {
            message: 'End date cannot be earlier than the start date',
          };
        }
      }
    } else {
      if (!start_date_valid) {
        errors.meeting_start_time = {
          message: 'Provided date is not in valid format',
        };
      }

      if (!end_date_valid) {
        errors.meeting_end_time = {
          message: 'Provided date is not in valid format',
        };
      }
    }

    validationParameters.forEach(({ fields, isInvalid }) => {
      Object.entries(fields).forEach(([name, params]) => {
        if (isInvalid(values[name], params)) {
          errors[name] = {
            message: params?.message ?? params ?? `${name} is invalid`,
          };
        }
      });
    });

    Object.entries(requiredFields).forEach(([name, message]) => {
      const value = values[name];

      if (value === undefined || value === null || value === '' || value?.length === 0) {
        errors[name] = { message };
      }
    });

    if (Object.keys(errors).length === 0) {
      return { values, errors: {} };
    }
    return { values: {}, errors };
  };
  return {
    resolver,
  };
};
