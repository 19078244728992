import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

function FormTextField({
  validation: rules = {},
  parseError = (error) => error.message,
  type,
  required,
  name,
  ...rest
}) {
  const { control } = useFormContext();
  if (required && !rules.required) {
    rules.required = 'This field is required';
  }

  if (type === 'email' && !rules.pattern) {
    rules.pattern = {
      // eslint-disable-next-line no-useless-escape
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Please enter a valid email address',
    };
  }

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => (
        <TextField
          {...rest}
          name={name}
          value={value ?? ''}
          onChange={(ev) => {
            onChange(ev);
            if (typeof rest.onChange === 'function') {
              rest.onChange(ev);
            }
          }}
          onBlur={onBlur}
          required={required}
          type={type}
          error={invalid}
          helperText={error ? parseError(error) : rest.helperText}
        />
      )}
    />
  );
}

export default FormTextField;
