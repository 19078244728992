import { Typography, styled, Alert, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import withCutCorner from '../../withCutCorner';
import { AdminCourseListItem, UserCourseListItem } from '../CourseListItem';
import { CourseListItem } from '../CourseListItem/CourseListItem';
import { errorMessage } from '../../../constants/global';
import { GetUserView, userViewType } from '../../../utils/GetUserView';

const refreshButtonStyles = {
  height: '20px',
};

const CourseListBox = styled((props) => <Box {...props} />)((theme) => ({
  overflow: 'auto',
  minHeight: '500px',
  maxHeight: '500px',
  borderStyle: 'solid',
  borderWidth: '0px 1px 1px 1px',
  borderColor: '#B9C6C6',
  background: 'white',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#EAEEEE',
    borderRadius: '90px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#D5DDDD',
    borderRadius: '90px',
  },
}));

const ListHeaderName = styled((props) => <Typography {...props} />)(() => ({
  component: 'span',
  variant: 'h3',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '19px',
  lineHeight: '100%',
}));

const ListHeader = withCutCorner(Box);

export function CourseList({ items, title, errors, handleRefresh }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userView = useMemo(() => GetUserView(pathname), [pathname]);
  const CourseListComponent = useMemo(
    () => (userView === userViewType.user ? UserCourseListItem : AdminCourseListItem),
    [userView]
  );
  return (
    <div style={{ padding: '80px', display: 'flex' }}>
      {items.map((list, index) => (
        <div key={title[index].name} style={{ marginInline: '10px', width: '100%', height: '100%' }}>
          <ListHeader
            sx={{
              display: 'flex',
              backgroundColor: title[index].color.main,
              color: title[index].color.contrastText ?? '#FFFFFF',
            }}
            cornerSize='1.5em'
            corner='TR'
          >
            <IconButton
              onClick={() =>
                userView === userViewType.admin
                  ? navigate('/admin/courses-filtration')
                  : navigate('/courses-filtration')
              }
            >
              <OpenInNewIcon sx={{ color: title[index].color.contrastText ?? '#FFFFFF', width: '45px' }} />
            </IconButton>
            <ListHeaderName sx={{ pl: '15px', pt: '22px', pb: '20px' }}>{title[index].name}</ListHeaderName>
          </ListHeader>
          <CourseListBox>
            <div style={{ padding: '1rem', paddingBottom: '0' }}>
              {errors[index] && (
                <Box sx={{ p: 2 }}>
                  <Alert severity='error'>
                    <>
                      {errorMessage}
                      <LoadingButton color='error' sx={refreshButtonStyles} onClick={handleRefresh}>
                        <RefreshIcon />
                        Refresh
                      </LoadingButton>
                    </>
                  </Alert>
                </Box>
              )}
              {!errors[index] && list === undefined && [...Array(3)].map((e, i) => <CourseListItem key={i} />)}
              {!errors[index] &&
                list !== undefined &&
                (list.length > 0 ? (
                  list.map((course) => (
                    <CourseListComponent courseDetails={course} key={`CourseListItem_${course.id}`} />
                  ))
                ) : (
                  <Box sx={{ p: 2 }}>
                    <Typography component='span'>There are no courses</Typography>
                  </Box>
                ))}
            </div>
          </CourseListBox>
        </div>
      ))}
    </div>
  );
}
