import { useQuery } from 'react-query';
import { UsersController } from '../../api/controller/users/UsersController';
import { GET_USERS_ME } from '../queries';

export const useGetMe = ({ onSuccess, onError }) => {
  const {
    data: currentUser,
    refetch,
    error,
    isLoading,
  } = useQuery([GET_USERS_ME], () => UsersController.getMe(), {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  });

  return {
    currentUser,
    refetch,
    error,
    isLoading,
  };
};
