export const headerMenuContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginInline: {
    xs: '1rem',
    sm: '2rem',
    lg: '3rem',
    xl: '5rem',
  },
  minHeight: '5rem',
};

export const buttonsContainerStyle = {
  display: 'flex',
  gap: '40px',
};

export const buttonStyle = {
  color: 'primary.main',
  bgcolor: 'secondary.main',
  padding: {
    xs: '4px 8px',
    md: '6px 12px',
    lg: '8px 16px',
    xl: '10px 20px',
  },
  borderRadius: '10px',
  '&:hover': {
    bgcolor: 'text.disabled',
    transition: '0.25s',
  },
};

export const addIconStyle = {
  fontSize: {
    md: 'h4.fontSize',
    lg: 'h3.fotSize',
    xl: 'h2.fotSize',
  },
  color: 'primary.main',
  marginRight: '9px',
};

export const buttonTextStyle = {
  textTransform: 'none',
  fontSize: {
    xs: 'p1.fontSize',
    md: 'regular.fontSize',
    xl: 'h6.fontSize',
  },
  fontWeight: 'bold',
};

export const closeButtonStyle = {
  padding: '18px 22px 25px',
  marginLeft: 'auto',
};

export const avatarStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3.75rem',
  height: '3.75rem',
  bgcolor: 'secondary.main',
  borderRadius: '50%',
  fontSize: '2rem',
  fontWeight: 'bold',
};

export const userNameStyle = {
  fontSize: 'p.fontSize',
  fontWeight: 'bold',
  lineHeight: 'h6.fontSize',
};

export const userInfoStyle = {
  fontSize: 'p2.fontSize',
  lineHeight: 'regular.fontSize',
};

export const userRoleStyle = {
  fontWeight: '500',
};

export const listStyle = {
  width: '21rem',
  paddingBlock: '0',
};

export const listItemButtonPaddingStyle = {
  padding: '10px 22px',
};

export const listItemWidthStyle = {
  minWidth: '0',
};

export const iconStyle = {
  color: 'primary.main',
  fontSize: 'h3.fontSize',
  marginRight: '22px',
};

export const listItemTextStyle = {
  fontSize: 'h6.fontSize',
  fontWeight: 'bold',
};
