import { useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, IconButton, Button, Typography, AppBar } from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { HandleCourse } from 'Components/Courses/HandleCourse/HandleCourse';
import { useCreateCourse } from 'hooks/courses';
import a4beeLogoYellow from '../../../assets/a4bee-logo-yellow.png';
import a4beeLogoBlack from '../../../assets/a4bee-logo.png';
import {
  headerMenuContainerStyle,
  buttonsContainerStyle,
  closeButtonStyle,
  addIconStyle,
  buttonStyle,
  buttonTextStyle,
} from '../style';
import MenuList from './MenuList';
import UserDetails from './UserDetails';
import { withAdminRole } from '../../WithRole/WithRole';

export default function HeaderMenu() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const openCloseDrawer = () => setOpenDrawer(!openDrawer);
  const navigate = useNavigate();
  const route = useLocation();
  const addNewCourseRef = useRef(null);
  const RestrictedNewCourseButton = withAdminRole(Button);
  const cardId = uuidv4();

  const handleCancel = useCallback(() => {
    if (addNewCourseRef.current) {
      addNewCourseRef.current.close();
    }
  }, [addNewCourseRef.current]);

  const { createCourse, isLoading: isSaving } = useCreateCourse({ onSuccess: handleCancel, setOpenDialog });
  const handleCourseSave = (data, cardId) => {
    createCourse({ cardId, data });
  };

  const handleAddNewCourse = useCallback(() => {
    if (addNewCourseRef?.current) {
      addNewCourseRef.current.open();
    }
  }, [addNewCourseRef?.current]);

  return (
    <>
      <AppBar sx={{ bgcolor: 'white', boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 7%)', position: 'sticky' }}>
        {/^\/admin/i.test(route.pathname) ? (
          <Box sx={{ bgcolor: 'raspberry.main', minHeight: '10px' }} />
        ) : (
          <Box sx={{ minHeight: '10px' }} />
        )}
        <Box sx={headerMenuContainerStyle}>
          <Button onClick={() => navigate('/')}>
            {/^\/admin/i.test(route.pathname) ? (
              <img src={a4beeLogoBlack} alt='a4bee logo' width='50px' height='45px' />
            ) : (
              <img src={a4beeLogoYellow} alt='a4bee logo' width='50px' height='45px' />
            )}
          </Button>
          <Box sx={buttonsContainerStyle}>
            {/^\/admin/i.test(route.pathname) && (
              <RestrictedNewCourseButton variant='contained' sx={buttonStyle} onClick={handleAddNewCourse}>
                <AddIcon sx={addIconStyle} />
                <Typography sx={buttonTextStyle}>Create new course</Typography>
              </RestrictedNewCourseButton>
            )}
            <IconButton sx={{ color: 'primary.main' }} onClick={openCloseDrawer}>
              <MenuIcon fontSize='large' />
            </IconButton>
          </Box>
        </Box>
        <Drawer anchor='right' open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Box sx={closeButtonStyle}>
            <IconButton sx={{ color: 'primary.main' }} onClick={openCloseDrawer}>
              <CloseIcon fontSize='large' />
            </IconButton>
          </Box>
          <UserDetails />
          <MenuList handleClose={openCloseDrawer} />
        </Drawer>
      </AppBar>
      <HandleCourse
        ref={addNewCourseRef}
        formId={`new_course_${cardId}`}
        cardId={cardId}
        onSubmit={handleCourseSave}
        isSaving={isSaving}
        handleCancel={handleCancel}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
}
