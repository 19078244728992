import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AdminPage } from 'Views/Admin/AdminPage';
import { ListOfInstructors } from 'Views/ListOfInstructors/ListOfInstructors';
import { FiltrationView } from 'Views/CourseFiltration/FiltrationView';
import Error from './Views/Error/Error';
import { HowToUseCertificate } from './Views/HowToUseCertificate/HowToUseCertificate';
import { InstructorsToDo } from './Views/InstructorsToDo/InstructorsToDo';
import { Login } from './Views/Login/Login';
import { MyProfile } from './Views/MyProfile/MyProfile';
import { Diploma } from './Views/Diploma/Diploma';
import { HomeDasboard } from './Views/Home/HomeDashboard';
import { MainLayout } from './layouts/mainLayout';
import { HeadlessLayout } from './layouts/headlessLayout';
import { useAuth } from './providers/auth';
import { withAdminRoleAndError } from './Components/WithRole/WithRole';
import { AuthProvider } from './providers/auth/provider';
import { UserDataProvider } from './providers/user/provider';

const RestrictedAdminPage = withAdminRoleAndError(AdminPage);
const RestrictedAdminFilatrationPage = withAdminRoleAndError(FiltrationView);

function PrivateRoute({ component: Compontent, isAuth, isInitialized }) {
  if (isInitialized) {
    return isAuth ? (
      <UserDataProvider>
        <Compontent />
      </UserDataProvider>
    ) : (
      <Navigate to='/login' />
    );
  }

  return null;
}

export function Router() {
  const {
    state: { isAuth, isInitialized },
  } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HeadlessLayout />}>
          <Route path='/diplomas/*' element={<Diploma />} />
          <Route path='/login' element={<Login />} />
        </Route>
        <Route element={<PrivateRoute isAuth={isAuth} isInitialized={isInitialized} component={MainLayout} />}>
          <Route path='/' element={<HomeDasboard />} />
          <Route path='/admin/*' element={<RestrictedAdminPage />} />
          <Route path='/admin/courses-filtration' element={<RestrictedAdminFilatrationPage />} />
          <Route path='/courses-filtration' element={<FiltrationView />} />
          <Route path='/how-to-use-certificate' element={<HowToUseCertificate />} />
          <Route path='/todo-for-instructor' element={<InstructorsToDo />} />
          <Route path='/myprofile' element={<MyProfile />} />
          <Route path='/list-of-instructors' element={<ListOfInstructors />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path='*' element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
