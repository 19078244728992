const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const listPaperStyle = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      maxWidth: 450,
    },
  },
};
