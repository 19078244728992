import { useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAuth } from 'providers/auth';
import { BeeplomaTheme } from 'theme/BeeplomaTheme';
import { useGetCourses, useGetMyCourses } from 'hooks/courses';
import { courseStatuses } from 'constants/course';
import { CoursePage } from './CoursePage';

function UserCoursePage() {
  const {
    state: { user },
  } = useAuth();

  const {
    courses,
    error: coursesError,
    isLoading: coursesLoading,
    refetch: refetchCourses,
  } = useGetCourses(courseStatuses.published);
  const {
    myCourses,
    error: myCoursesError,
    isLoading: myCoursesLoading,
    refetch: refetchMyCourses,
  } = useGetMyCourses();
  const [courseData, setCourseData] = useState([]);
  const [errors, setErrors] = useState([false, false, false]);

  useEffect(() => {
    const result = [];
    if (!coursesLoading && !myCoursesLoading) {
      if (courses) {
        courses?.items?.map((course) => {
          course.status = courseStatuses.published;
          course.is_mine = false;
          return result.push(course);
        });
      }

      if (myCourses) {
        myCourses?.items?.map((myCourse) => {
          myCourse.status = courseStatuses.published;
          myCourse.is_mine = true;
          return result.push(myCourse);
        });
      }

      setCourseData(result.filter((v1, i, ar) => ar.findLastIndex((v2) => v2.id === v1.id) === i));
    }
  }, [courses, myCourses, coursesLoading, myCoursesLoading]);

  useEffect(() => {
    setErrors([!!myCoursesError, !!coursesError, !!coursesError]);
  }, [coursesError, myCoursesError]);

  const coursesDict = useMemo(
    () => [
      {
        color: BeeplomaTheme.palette.lightGrey,
        name: 'My courses',
        predicate: (course) =>
          course.is_mine &&
          !course.is_finished &&
          dayjs(course?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') >=
            dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      },
      {
        color: BeeplomaTheme.palette.lightGrey,
        name: 'Upcoming courses',
        predicate: (course) =>
          !course.is_mine &&
          dayjs(course?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') >=
            dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      },
      {
        color: BeeplomaTheme.palette.lightGrey,
        name: 'Past courses',
        predicate: (course) =>
          dayjs(course?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') < dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      },
    ],
    [user, BeeplomaTheme]
  );

  const pageInfo = useMemo(() => ({
    emoji: '👋',
    title: `Hi, ${user.name}!`,
    subtitle: `Here you can submit on courses you like. Moreover, if you want create your own course you can contact with admin.`,
  }));

  return (
    <CoursePage
      courses={courseData}
      courseDict={coursesDict}
      pageInfo={pageInfo}
      errors={errors}
      handleRefresh={() => {
        setCourseData([]);
        refetchMyCourses() && refetchCourses();
      }}
    />
  );
}

export default UserCoursePage;
