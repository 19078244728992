import Footer from 'Components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import HeaderMenu from '../Components/Header/HamburgerMenu/HeaderMenu';

export function MainLayout() {
  return (
    <>
      <HeaderMenu />
      <Outlet />
      <Footer />
    </>
  );
}
