import { TagsController } from 'api/controller/tags/TagsController';
import { useQuery } from 'react-query';
import { GET_TAGS_ALL } from '../queries';

export const useGetAllTags = () => {
  const { data: tags, error, isLoading } = useQuery([GET_TAGS_ALL], () => TagsController.getAll());

  return {
    tags,
    error,
    isLoading,
  };
};
