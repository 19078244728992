import { Link, ListItemButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import withCutCorner from 'Components/withCutCorner';
import { useNavigate } from 'react-router-dom';
import { cutCornerStyle, FooterContainer, listItemStyle, listStyle } from './style';

function Footer() {
  const navigate = useNavigate();
  const CornerBox = withCutCorner(Box);

  return (
    <FooterContainer>
      <Box sx={listStyle}>
        <Typography variant='p1bold'>Beeploma</Typography>
        <ListItemButton sx={listItemStyle} onClick={() => navigate('/myprofile')}>
          <Typography variant='p1'>My Profile</Typography>
        </ListItemButton>
        <ListItemButton sx={listItemStyle} onClick={() => navigate('/')}>
          <Typography variant='p1'>Courses</Typography>
        </ListItemButton>
        <ListItemButton sx={listItemStyle} onClick={() => navigate('/list-of-instructors')}>
          <Typography variant='p1'>Instructors</Typography>
        </ListItemButton>
        <ListItemButton sx={listItemStyle}>
          <Typography variant='p1'>Settings</Typography>
        </ListItemButton>
        <ListItemButton sx={listItemStyle} onClick={() => navigate('/todo-for-instructor')}>
          <Typography variant='p1'>Instructor's To Do</Typography>
        </ListItemButton>
        <ListItemButton sx={listItemStyle} onClick={() => navigate('/how-to-use-certificate')}>
          <Typography variant='p1'>How to Use Certificate</Typography>
        </ListItemButton>
      </Box>
      <Box sx={listStyle}>
        <Typography variant='p1bold'>Support</Typography>
        <Typography variant='p1bold'>Contact with Admin</Typography>
        <Typography variant='p1'>Gabriela Lech</Typography>
        <Typography variant='p1'>
          <Link href='mailto:gabriela.lech@a4bee.com'>gabriela.lech@a4bee.com</Link>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', cursor: 'default' }}>
        <CornerBox corner='TR' cornerSize='17px' sx={cutCornerStyle}>
          <Typography variant='p1bold'>A4BEE HQ Wrocław</Typography>
          <Typography variant='p1'>ul. Muchoborska 8</Typography>
          <Typography variant='p1'>54-424, Wrocław, Poland</Typography>
        </CornerBox>
        <CornerBox corner='TR' cornerSize='17px' sx={cutCornerStyle}>
          <Typography variant='p1bold'>A4BEE Warsaw</Typography>
          <Typography variant='p1'>ul. Żurawia 45</Typography>
          <Typography variant='p1'>00-680, Warsaw, Poland</Typography>
        </CornerBox>
      </Box>
    </FooterContainer>
  );
}

export default Footer;
