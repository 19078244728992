import { useUserData } from 'providers/user';
import FormContainer from 'Components/Form/FormContainer';
import { courseStatuses } from 'constants/course';
import FormSelectParticipants from 'Components/Form/Inputs/FormSelectParticipants';

export function HandleParticipantForm({ id, onSubmit, courseDetails }) {
  const {
    state: { places, users: participants },
  } = useUserData();

  const defaultValues = {
    participants: [],
  };

  return (
    <FormContainer
      formProps={{ id }}
      onSuccess={(e) => {
        onSubmit(e);
      }}
      useFormProps={{ defaultValues }}
      loadValues={courseDetails}
    >
      <FormSelectParticipants
        coursePlaces={courseDetails?.place_ids}
        places={places}
        participants={participants}
        name='participants'
        disabled={courseDetails?.status === courseStatuses.draft || courseDetails?.id === undefined}
      />
    </FormContainer>
  );
}
