import { useQuery } from 'react-query';
import { UsersController } from '../../api/controller/users/UsersController';
import { GET_USERS_IS_INSTRUCTOR } from '../queries';

export const useGetAllIsInstructor = (pageNumber) => {
  const {
    data: users,
    error,
    isLoading,
  } = useQuery([GET_USERS_IS_INSTRUCTOR], () => UsersController.getAllIsInstructor(pageNumber));

  return {
    users,
    error,
    isLoading,
  };
};
