import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { BeeplomaTheme } from '../../theme/BeeplomaTheme';
import { useStyles } from './styles';
import { ReactComponent as TechLogo } from '../../theme/tech-logo.svg';
import { ReactComponent as A4BEELogo } from '../../theme/a4bee-logo.svg';

export function A4BeeBackground() {
  const styles = useStyles();
  return (
    <Box className={styles.duocolor} flexDirection='column' zIndex='0' width='100%' height='100%' display='flex'>
      <Box zIndex='1' id='header'>
        <Container>
          <Box height='150px' display='flex' className={`${styles.corner} yellow left`}>
            <Box
              className={`${styles.corner} grey`}
              width='50%'
              height='150px'
              bgcolor={BeeplomaTheme.palette.secondary.main}
            />
            <Box
              className={`${styles.corner} white`}
              width='50%'
              height='150px'
              bgcolor={BeeplomaTheme.palette.background.grey}
            />
          </Box>
        </Container>
      </Box>
      <Box height='100%' zIndex='1' id='content'>
        <Box className={styles.logosbox}>
          <TechLogo img alt='tech academy logo' id='tech-img' />
          <A4BEELogo img alt='a4bee company logo' id='logo-img' />
        </Box>
        <Container sx={{ height: '100%' }}>
          <Box height='100%' overflow='hidden' display='flex'>
            <Box width='50%' height='100%' bgcolor={BeeplomaTheme.palette.secondary.main} />
            <Box width='50%' height='100%' bgcolor={BeeplomaTheme.palette.background.grey} />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
