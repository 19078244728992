export const initialsAvatarStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '9.35rem',
  height: '9.35rem',
  bgcolor: 'secondary.main',
  borderRadius: '50%',
  fontSize: '3rem',
  fontWeight: '700',
  cursor: 'default',
};

export const myProfileContainerStyle = {
  marginBlock: '2rem',
  marginInline: {
    xs: '1rem',
    sm: '2rem',
    lg: '3rem',
    xl: '5rem',
  },
};

export const userDetailsStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '3.75rem',
};
