import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { avatarStyle, userInfoStyle, userNameStyle, userRoleStyle } from '../style';
import { useAuth } from '../../../providers/auth';
import { GetInitials } from '../../../utils/GetInitials.js';

export default function UserDetails() {
  const {
    state: { user },
  } = useAuth();

  const route = useLocation();

  return (
    <Box sx={{ display: 'flex', gap: '18px', alignItems: 'center', padding: '0 22px', marginBottom: '19px' }}>
      <Box sx={avatarStyle}>{GetInitials(user.name)}</Box>
      <Box>
        <Typography sx={userNameStyle}>{user.name}</Typography>
        <Typography sx={userInfoStyle}>{user.mail}</Typography>
        {user.roles.includes('admin') && (
          <Typography sx={[userRoleStyle, { color: 'raspberry.main' }]}>admin</Typography>
        )}
      </Box>
    </Box>
  );
}
