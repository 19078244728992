import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/styles';
import { BeeplomaTheme } from 'theme/BeeplomaTheme';

export const buttonTextStyle = {
  textTransform: 'capitalize',
  fontWeight: 'bold',
};

export const buttonIconStyle = {
  paddingRight: {
    xs: '2px',
    md: '4px',
    lg: '6px',
    xl: '8px',
  },
  fontSize: {
    xs: '1.75rem',
    sm: '1.75rem',
    md: '1.5rem',
  },
};

export const buttonStyle = {
  padding: {
    xs: '4px 8px',
    md: '6px 12px',
    lg: '8px 16px',
    xl: '10px 20px',
  },
  borderRadius: '10px',
  '&:hover': {
    bgcolor: 'success.main',
    color: 'primary.contrastText',
    transition: '0.25s',
  },
};

export const publishButtonStyle = {
  color: 'primary.main',
  bgcolor: 'secondary.main',
};

export const draftButtonStyle = {
  color: 'primary.contrastText',
  bgcolor: 'grey.dark',
};

export const cancelButtonStyle = {
  color: 'primary.contrastText',
  bgcolor: 'error.main',
};

export const typographyStyle = {
  wordBreak: 'break-word',
};
