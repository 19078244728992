import { Dialog, Box, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { buttonIconStyle, buttonTextStyle } from '../../Form/style';
import { buttonStyle } from '../../Header/style';
import a4beeLogoYellow from '../../../assets/a4bee-logo-yellow.png';
import { useGetAll as useGetAllPlaces } from '../../../hooks/places/useGetAll';

export function UserCourseDialog({ isOpen, setIsOpen, courseDetails, handleAddSelf }) {
  const { places } = useGetAllPlaces();

  const idRemoteCourse = places?.find((place) => place.name === 'Remote')?.id;
  const idExternalCourse = places?.find((place) => place.name === 'External')?.id;
  const idOnsitePlace = courseDetails?.place_ids?.filter(
    (places) => places !== idRemoteCourse && places !== idExternalCourse
  )[0];
  const coursePlaceName = places?.find((place) => place.id === idOnsitePlace)?.name || 'At the place';
  const disabledOnsite =
    courseDetails?.no_available_places === 0 ||
    courseDetails?.place_ids.every((id) => id === idRemoteCourse) ||
    courseDetails?.place_ids.every((id) => id === idExternalCourse) ||
    dayjs(courseDetails?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') <
      dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS') ||
    Math.ceil(dayjs(courseDetails?.meeting_start_time).diff(dayjs(), 'day', true)) <= 3;
  const disabledRemotely =
    courseDetails?.place_ids?.every((id) => id !== idRemoteCourse) ||
    courseDetails?.no_available_places_online === 0 ||
    dayjs(courseDetails?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') <
      dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS') ||
    (courseDetails?.type === 'Workshop' &&
      Math.ceil(dayjs(courseDetails?.meeting_start_time).diff(dayjs(), 'day', true)) <= 1);

  const handleClose = () => {
    setIsOpen(false);
  };

  const addSelfOnsite = () => {
    const onsiteId = courseDetails.place_ids.find((id) => id !== idRemoteCourse);
    if (onsiteId) handleAddSelf(onsiteId);
    setIsOpen(false);
  };

  const addSelfOnline = () => {
    if (courseDetails.place_ids.find((id) => id === idRemoteCourse)) handleAddSelf(idRemoteCourse);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      BackdropProps={{ style: { background: 'rgba(0,0,0,0.4)' } }}
      PaperProps={{ style: { boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 2%)' } }}
      aria-labelledby='alert-dialog-title'
    >
      <Box sx={{ padding: '2rem 2rem', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <img src={a4beeLogoYellow} alt='a4bee logo' width='40px' height='35px' />
          <DialogTitle sx={{ padding: '5px 0px 25px 0px', cursor: 'default' }}>
            How do you want to participate in the course?
          </DialogTitle>
        </Box>
        <DialogActions sx={{ justifyContent: 'space-around' }}>
          <LoadingButton
            sx={{ buttonStyle, color: 'primary.main', bgcolor: 'secondary.main' }}
            size='medium'
            type='submit'
            onClick={addSelfOnsite}
            disabled={disabledOnsite}
          >
            <PersonIcon sx={buttonIconStyle} />
            <Typography sx={buttonTextStyle}>{coursePlaceName}</Typography>
          </LoadingButton>
          <LoadingButton
            sx={{ buttonStyle, color: 'primary.main', bgcolor: 'secondary.main' }}
            size='medium'
            type='submit'
            onClick={addSelfOnline}
            disabled={disabledRemotely}
          >
            <ComputerIcon sx={buttonIconStyle} />
            <Typography sx={buttonTextStyle}>Remotely</Typography>
          </LoadingButton>
        </DialogActions>
        {disabledOnsite && disabledRemotely ? (
          <Typography variant='p1' sx={{ marginTop: '25px', color: 'text.secondary' }}>
            * If none of the options is available, it may be due to all seats on the course being taken or the
            registration deadline for the course has passed.
          </Typography>
        ) : null}
      </Box>
    </Dialog>
  );
}
