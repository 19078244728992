import { useCallback, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { courseStates } from 'constants/course';
import { useGetCourseDetails } from 'hooks/courses';
import { useLocation } from 'react-router-dom';
import { GetUserView, userViewType } from '../../../utils/GetUserView';
import { CourseListItem } from './CourseListItem';
import { HandleParticipant } from '../HandleParticipant/HandleParticipant';

export function AdminCourseListItem({ courseDetails, draft }) {
  const newParticipantDialogRef = useRef(null);
  const { courseDetails: detailed, refetchDetails, isLoading } = useGetCourseDetails(courseDetails?.id);
  const { pathname } = useLocation();
  const userView = useMemo(() => GetUserView(pathname), [pathname]);

  const handleActionClick = useCallback(async () => {
    if (newParticipantDialogRef.current) {
      if (detailed === undefined) {
        await refetchDetails();
      }
      newParticipantDialogRef.current.open();
    }
  }, [newParticipantDialogRef.current]);

  const handleSuccess = useCallback(() => {}, []);

  const action = useMemo(
    () => ({
      takeAction: handleActionClick,
      isLoading,
    }),
    [handleActionClick]
  );

  const isNotFinished = useMemo(
    () =>
      userView === userViewType.admin &&
      courseDetails?.is_finished === false &&
      dayjs(courseDetails?.meeting_end_time).format('YYYY-MM-DDTHH:mm:ss.SSS') <
        dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    [courseDetails]
  );

  const state = useMemo(() => {
    if (isNotFinished) return courseStates.isNotFinished;
    return courseStates.admin;
  }, [isNotFinished]);

  return (
    <>
      <CourseListItem courseDetails={courseDetails} registrationAction={action} state={state} />
      <HandleParticipant
        ref={newParticipantDialogRef}
        formId={`add_participant_${courseDetails?.id}`}
        courseId={courseDetails?.id}
        onSuccess={handleSuccess}
        courseDetails={courseDetails}
      />
    </>
  );
}
