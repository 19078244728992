import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/styles';

const StyledIcon = styled(({ Icon, ...props }) => <Icon {...props} />)(({ theme }) => ({
  color: theme.palette.grey.dark,
  height: 24,
  width: 24,
}));

function TextWithIcon({ text, icon, marginTop = '0' }) {
  return (
    <div style={{ display: 'flex', gap: '1em', marginTop }}>
      <StyledIcon Icon={icon} />
      <Typography style={{ wordBreak: 'break-word' }} component='span' lineHeight='normal' variant='h6bold'>
        {text ?? <Skeleton width='100px' />}
      </Typography>
    </div>
  );
}

export default TextWithIcon;
