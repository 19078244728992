export const GET_COURSES = 'get_courses';
export const GET_MY_COURSES = 'get_my_courses';
export const GET_COURSE_DETAILS = 'get_course_details';
export const GET_DIPLOMA_PARTICIPANT = 'get_diploma_participant';

export const GET_INSTRUCTORS = 'get_instructors';
export const GET_INSTRUCTOR_DETAILS = 'get_instructor_details';
export const GET_INSTRUCTOR_COURSES = 'get_instructor_courses';

export const GET_DIPLOMA_DETAILS = 'get_diploma_details';

export const GET_USERS_ME = 'get_users_me';
export const GET_USERS_ALL = 'get_users_all';
export const GET_USERS_ALL_WITH_SIGNATURE = 'get_users_all_with_signature';
export const GET_USERS_IS_INSTRUCTOR = 'get_users_all_is_instructor';

export const GET_PLACES_ALL = 'get_places_all';

export const GET_TAGS_ALL = 'get_tags_all';
