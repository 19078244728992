import { makeStyles } from '@mui/styles';
import { BeeplomaTheme } from '../../theme/BeeplomaTheme';

export const useStyles = makeStyles({
  certificate: {
    '@media (min-width: 769px)': {
      width: '40%',
    },
    '@media (max-width: 768px)': {
      width: '80%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});
