import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { useEffect, useState } from 'react';
import { useGetMe } from '../../hooks/users/useGetMe';
import AuthContext from './context';

export function AuthProvider({ children }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});

  const { refetch: getCurrentUser } = useGetMe({
    onSuccess: () => {},
    onError: () => {},
  });

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          getUserData()
            .then((userData) => {
              setUserData(userData);
            })
            .finally(() => {
              setIsInitialized(true);
            });
          break;
        case 'signOut':
          unsetUserData();
          break;
        default:
          break;
      }
    });

    getUserData()
      .then((userData) => {
        setUserData(userData);
      })
      .catch(() => {
        unsetUserData();
      })
      .finally(() => {
        setIsInitialized(true);
      });

    return unsubscribe;
  }, []);

  function setUserData(user) {
    setUser(user);
    setIsAuth(true);
  }

  function unsetUserData() {
    setUser(null);
    setIsAuth(false);
  }

  async function getUserData() {
    const auth_user = await Auth.currentAuthenticatedUser();
    if (auth_user) {
      return Promise.all([Auth.currentSession(), getCurrentUser()])
        .then(([session, data]) => ({
          name: auth_user?.attributes?.name,
          mail: auth_user?.attributes?.email,
          roles: session.accessToken.payload['cognito:groups'],
          additionalInfo: data.data,
          tokens: {
            id: session.idToken.jwtToken,
            refresh: session.refreshToken.token,
            access: session.accessToken.jwtToken,
          },
        }))
        .catch((error) => {
          throw error;
        });
    }

    return {};
  }

  const signIn = () => {
    Auth.federatedSignIn({ provider: 'Google' });
  };

  const signOut = () => {
    Auth.signOut();
  };

  const value = {
    state: { isAuth, isInitialized, user },
    actions: { signIn, signOut },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
