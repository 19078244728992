import { forwardRef } from 'react';

function withCutCorner(WrapperComponent) {
  return forwardRef(({ corner = 'TL', cornerSize = '50px', className, style, ...props }, ref) => (
    <WrapperComponent
      {...props}
      className={`corner-${corner} ${className}`}
      style={{ ...style, '--corner': cornerSize }}
      ref={ref}
    />
  ));
}

export default withCutCorner;
