import { Typography } from '@mui/material';
import { Box } from '@mui/system';

function FiltrationHeader() {
  return (
    <Box p='80px' pb='0px' display='flex' gap='1em'>
      <Box>
        <Typography variant='h3bold'>🧠</Typography>
      </Box>
      <Box>
        <Box>
          <Typography variant='h3bold'>Courses filtration</Typography>
        </Box>
        <Box sx={{ pb: 0, pt: '30px' }}>
          <Typography variant='regularbold' style={{ whiteSpace: 'pre-line' }}>
            You are in filtration mode. You can find interesting courses by filter by date, status, instructor,
            location, hashtags and many more!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default FiltrationHeader;
